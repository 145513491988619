import {defineStore} from "pinia"
import type {ITaskCategory} from "@/library/models/task-category.interface"
import {filter, keyBy, reject} from "lodash"
import {
  baseCollectionStoreActions,
  baseCollectionStoreGetters,
  baseCollectionStoreState,
} from "@/library/stores/_base-collection"
import type {IBaseCollectionStoreState} from "@/library/stores/_base-collection"
import {useCaseStore} from "@/library/stores/case"
import {PLANNING_TIME_CATEGORY_ID} from "@/library/models/task-category.interface"
import i18next from "i18next"
import {TaskCategoryApi} from "@/library/api/task-category"

export const useTaskCategoryStore = defineStore("task-category", {
  state: (): IBaseCollectionStoreState<ITaskCategory> => ({
    ...baseCollectionStoreState<ITaskCategory>(),
  }),
  getters: {
    ...baseCollectionStoreGetters<ITaskCategory>(),
    categoriesById(state): Record<ITaskCategory["id"], ITaskCategory> {
      return keyBy(state.items, "id")
    },
    timeCategories(): ITaskCategory[] {
      const allTimeCategories = filter(this.categoriesById, {type: "time"})
      if (useCaseStore().isActiveCasePreplanner) {
        return reject(allTimeCategories, ({id}) => id === PLANNING_TIME_CATEGORY_ID)
      } else {
        return allTimeCategories
      }
    },
    timeCategoriesById(): Record<ITaskCategory["id"], ITaskCategory> {
      return keyBy(this.timeCategories, "id")
    },
    contentCategories(): ITaskCategory[] {
      return filter(
        this.categoriesById,
        category => category.type === "content" && VALID_CONTENT_CATEGORY_IDS.includes(category.id),
      )
    },
    contentCategoriesById(): Record<ITaskCategory["id"], ITaskCategory> {
      return keyBy(this.contentCategories, "id")
    },
  },
  actions: {
    ...baseCollectionStoreActions<ITaskCategory>(),
    async fetchCategories(): Promise<null | ITaskCategory[]> {
      return this.cachedFetch(async () => patchNamesOnto(await TaskCategoryApi.fetch()))
    },
  },
})

function patchNamesOnto(categories: ITaskCategory[]) {
  const namesById = i18next.t("roadmap:roadmap_section.task_categories") as unknown as Record<
    ITaskCategory["id"],
    ITaskCategory["name"]
  >

  for (const c of categories) {
    c.name = namesById[c.id]
  }

  return categories
}

const VALID_CONTENT_CATEGORY_IDS = [
  7, // Learn
  20, // Assets
  30, // Legal
  31, // Benefits
  32, // Notifications
  33, // Debts & taxes
  34, // Closing the estate
]
