import {createLogger} from "@/library/domain/logger"

export default function setupHubspotForms() {
  if (!import.meta.env.VITE_HUBSPOT_FORM_SRC) {
    createLogger().info(
      "@/client/enduser/src/setup/setupHubspotForms.ts",
      "Skipping initialization of Hubspot due to missing VITE_HUBSPOT_FORM_SRC",
    )
    return
  }

  const script = document.createElement("script")
  script.src = import.meta.env.VITE_HUBSPOT_FORM_SRC
  document.body.appendChild(script)
}
