import type {IBaseCollectionStoreState} from '@/library/stores/_base-collection'
import {
  baseCollectionStoreActions,
  baseCollectionStoreGetters,
  baseCollectionStoreState
} from '@/library/stores/_base-collection'
import {defineStore} from 'pinia'
import type {ICase} from '@/library/models/case.interface'
import {keyBy} from 'lodash'
import type {ITaskContent} from '@/library/models/task-content.interface'
import {TaskContentApi} from '@/library/api/task-content.api'
import type {ICaseTask} from '@/library/models/case-task.interface'
import {createLogger} from '@/library/domain/logger'

interface ITaskContentStoreState extends IBaseCollectionStoreState<ITaskContent> {
}

export const useTaskContentStore = defineStore('task-content', {
  state: (): ITaskContentStoreState => ({
    ...baseCollectionStoreState<ITaskContent>()
  }),
  getters: {
    ...baseCollectionStoreGetters<ITaskContent>(),
    contentByTaskId(): Record<ITaskContent['id'], ITaskContent> {
      return keyBy(this.items, 'task_id')
    }
  },
  actions: {
    ...baseCollectionStoreActions<ITaskContent>(),
    async pull(caseId: ICase['id']): Promise<null | ITaskContent[]> {
      return this.cachedFetch(async () => await TaskContentApi.fetchForCase(caseId), false)
    },
    getContentForCaseTask(caseTask: ICaseTask, field: 'name' | 'description' | 'instructions'): string {
      if (caseTask.task_id) {
        // get the content for the task from this store
        const taskContent = this.contentByTaskId[caseTask.task_id]
        if (!taskContent) {
          createLogger().error(
            '@/library/stores/task-content.store',
            'Could not get task_content for case_task',
            {extra: {case_task_id: caseTask.id, task_id: caseTask.task_id}}
          )
          return ''
        }

        return taskContent[field]
      }

      // otherwise just get content stored on the case task (if it's a custom task)
      return caseTask[field]
    }
  }
})

