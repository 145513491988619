import {defineStore} from "pinia"
import {useCaseMembersStore} from "@/library/stores/case-member"

export const usePermissionStore = defineStore("permissions", {
  state: () => ({}),
  getters: {
    canAccessRoadmap() {
      const caseMemberStore = useCaseMembersStore()

      return caseMemberStore.isAuthMemberAnOwner || caseMemberStore.isAuthMemberACollaborator
    },
    canAccessQuestionnaire() {
      const caseMemberStore = useCaseMembersStore()

      return caseMemberStore.isAuthMemberAnOwner || caseMemberStore.isAuthMemberACollaborator
    },
    canAccessDigitalVault() {
      const caseMemberStore = useCaseMembersStore()

      return caseMemberStore.isAuthMemberAnOwner || caseMemberStore.isAuthMemberACollaborator
    },
  },
  actions: {},
})
