// todo: convert this to an object and build types over that
// todo: convert values to match keys identically
export enum SectionTypes {
  // form filler
  DECEASED_PERSONAL_INFO = "deceased_personal_info",
  DECEASED_CONTACT_INFO = "deceased_contact_info",
  DECEASED_IMPORTANT_DOCS = "deceased_important_docs",
  DECEASED_PARENT_INFO = "deceased_parent_info",
  DECEASED_END_OF_LIFE_INFO = "deceased_end_of_life_info",

  APPLICANT = "applicant",
  FUNERAL_HOME = "funeral_home",
  PAYMENT = "payment",

  SPOUSE_PERSONAL_INFO = "spouse_personal_info",
  SPOUSE_CONTACT_INFO = "spouse_contact_info",
  SPOUSE_MARRIAGE_INFO = "spouse_marriage_info",
  SPOUSE_IMPORTANT_DATES = "spouse_important_dates",
  SPOUSE_PARENT_INFO = "spouse_parent_info",
  CHILDREN = "children",

  // estate reps
  ESTATE_REPS = "estate_reps",

  // digital vault -- Listings
  CONTACTS = "contacts", //contacts

  ID_DOCUMENTS = "id_documents", // licenses
  FILTER_ID_DOCUMENT__accessible_parking_permit = "id_document__accessible_parking_permit",
  FILTER_ID_DOCUMENT__drivers_license = "id_document__drivers_license",
  FILTER_ID_DOCUMENT__firearm_license = "id_document__firearm_license",
  FILTER_ID_DOCUMENT__health_card = "id_document__health_card",
  FILTER_ID_DOCUMENT__nexus = "id_document__nexus",
  FILTER_ID_DOCUMENT__passport = "id_document__passport",

  KEY_DOCUMENTS = "key_documents", // legal
  FILTER_KEY_DOCUMENTS__marriage_contract = "key_document__marriage_contract",
  FILTER_KEY_DOCUMENTS__military = "key_document__military",
  FILTER_KEY_DOCUMENTS__tax_documents = "key_document__tax_documents",
  FILTER_KEY_DOCUMENTS__trust = "key_document__trust",

  INSURANCE = "insurance", // insurance
  FILTER_INSURANCE__life__northwestern_mutual = "insurance__life__northwestern_mutual",
  FILTER_INSURANCE__life__massmutual = "insurance__life__massmutual",
  FILTER_INSURANCE__life__new_york_life = "insurance__life__new_york_life",
  FILTER_INSURANCE__life__prudential = "insurance__life__prudential",
  FILTER_INSURANCE__life__lincoln_financial = "insurance__life__lincoln_financial",
  FILTER_INSURANCE__life__john_hancock = "insurance__life__john_hancock",
  FILTER_INSURANCE__life__pacific_life = "insurance__life__pacific_life",
  FILTER_INSURANCE__life__corebridge_financial = "insurance__life__corebridge_financial",
  FILTER_INSURANCE__life__midland_national = "insurance__life__midland_national",
  FILTER_INSURANCE__life__nationwide = "insurance__life__nationwide",
  FILTER_INSURANCE__life__pennmutual = "insurance__life__pennmutual",
  FILTER_INSURANCE__life__equitable = "insurance__life__equitable",
  FILTER_INSURANCE__life__national_life = "insurance__life__national_life",
  FILTER_INSURANCE__life__manulife = "insurance__life__manulife",
  FILTER_INSURANCE__life__industrial_alliance = "insurance__life__industrial_alliance",
  FILTER_INSURANCE__life__bmo = "insurance__life__bmo",
  FILTER_INSURANCE__life__ivari = "insurance__life__ivari",
  FILTER_INSURANCE__health_medical = "insurance__health_medical",
  FILTER_INSURANCE__home_property = "insurance__home_property",
  FILTER_INSURANCE__life = "insurance__life",
  FILTER_INSURANCE__vehicle = "insurance__vehicle",

  FINANCIAL_INFORMATION = "financial_information", // finances
  FILTER_FINANCIAL_INFORMATION__bank_financial_services_provider = "financial_information__bank_financial_services_provider",
  FILTER_FINANCIAL_INFORMATION__credit_card = "financial_information__credit_card",
  FILTER_FINANCIAL_INFORMATION__investment_provider = "financial_information__investment_provider",
  FILTER_FINANCIAL_INFORMATION__lease = "financial_information__lease",
  FILTER_FINANCIAL_INFORMATION__loan = "financial_information__loan",
  FILTER_FINANCIAL_INFORMATION__pension = "financial_information__pension",
  FILTER_FINANCIAL_INFORMATION__philanthropy_charity = "financial_information__philanthropy_charity",

  UTILITIES = "utilities", // expenses
  FILTER_UTILITY__electricity = "utility__electricity",
  FILTER_UTILITY__property_taxes = "utility__property_taxes",
  FILTER_UTILITY__gas_hydro = "utility__gas_hydro",
  FILTER_UTILITY__water = "utility__water",
  FILTER_UTILITY__internet = "utility__internet",
  FILTER_UTILITY__phone = "utility__phone",

  PROPERTY = "property", // property
  FILTER_PROPERTY__computer = "property__computer",
  FILTER_PROPERTY__firearm = "property__firearm",
  FILTER_PROPERTY__real_estate = "property__real_estate",
  FILTER_PROPERTY__vehicle = "property__vehicle",

  MEMBERSHIPS = "memberships", // accounts
  FILTER_ACCOUNT__digital_account__paypal = "account__digital_account__paypal",
  FILTER_ACCOUNT__digital_account__google = "account__digital_account__google",
  FILTER_ACCOUNT__digital_account__facebook = "account__digital_account__facebook",
  FILTER_ACCOUNT__digital_account__instagram = "account__digital_account__instagram",
  FILTER_ACCOUNT__digital_account__linkedin = "account__digital_account__linkedin",
  FILTER_ACCOUNT__digital_account__pinterest = "account__digital_account__pinterest",
  FILTER_ACCOUNT__digital_account__twitter = "account__digital_account__twitter",
  FILTER_ACCOUNT__digital_account__flickr = "account__digital_account__flickr",
  FILTER_ACCOUNT__computer_login = "account__computer_login",
  FILTER_ACCOUNT__crypto_wallet = "account__crypto_wallet",

  // EXECUTORS = "executors", // executors
  // DIGITAL = "digital", // digital
  BUSINESS_DOCUMENTS = "business_documents", // business
  FILTER_BUSINESS_DOCUMENTS__letter_of_intent = "business_documents__letter_of_intent",
  FILTER_BUSINESS_DOCUMENTS__succession_plan = "business_documents__succession_plan",
  FILTER_BUSINESS_DOCUMENTS__asset = "business_documents__asset",
  FILTER_BUSINESS_DOCUMENTS__liability = "business_documents__liability",
  FILTER_BUSINESS_DOCUMENTS__digital_asset = "business_documents__digital_asset",
  FILTER_BUSINESS_DOCUMENTS__tax = "business_documents__tax",
  FILTER_BUSINESS_DOCUMENTS__property_title = "business_documents__property_title",
  FILTER_BUSINESS_DOCUMENTS__insurance_policy = "business_documents__insurance_policy",

  FARM_DOCUMENTS = "farm_documents",
  FILTER_FARM_DOCUMENTS__operating_agreement = "farm_documents__operating_agreement",
  FILTER_FARM_DOCUMENTS__land_title = "farm_documents__land_title",
  FILTER_FARM_DOCUMENTS__equipment_title = "farm_documents__equipment_title",
  FILTER_FARM_DOCUMENTS__insurance_policy = "farm_documents__insurance_policy",
  FILTER_FARM_DOCUMENTS__lease = "farm_documents__lease",
  FILTER_FARM_DOCUMENTS__water_rights = "farm_documents__water_rights",
  FILTER_FARM_DOCUMENTS__conservation_program_contract = "farm_documents__conservation_program_contract",
  FILTER_FARM_DOCUMENTS__environmental_compliance = "farm_documents__environmental_compliance",
  FILTER_FARM_DOCUMENTS__financial_statement = "farm_documents__financial_statement",
  FILTER_FARM_DOCUMENTS__tax = "farm_documents__tax",
  FILTER_FARM_DOCUMENTS__agricultural_loan = "farm_documents__agricultural_loan",
  FILTER_FARM_DOCUMENTS__transfer_on_death_deed = "farm_documents__transfer_on_death_deed",
  FILTER_FARM_DOCUMENTS__trust = "farm_documents__trust",
  FILTER_FARM_DOCUMENTS__estate_plan_integration = "farm_documents__estate_plan_integration",

  // digital vault - Non listings
  PROFILE = "profile", // composite of [DECEASED_PERSONAL_INFO, DECEASED_CONTACT_INFO, DECEASED_IMPORTANT_DOCS, DECEASED_PARENT_INFO]
  SPOUSE = "spouse", // composite of [SPOUSE_PERSONAL_INFO, SPOUSE_CONTACT_INFO, SPOUSE_MARRIAGE_INFO, SPOUSE_IMPORTANT_DATES, SPOUSE_PARENT_INFO]
  FUNERAL = "funeral", // composite of [END_OF_LIFE, FINAL_WISHES_RESTING_PLACE, FINAL_WISHES_FAMILY, FINAL_WISHES_SERVICE, FINAL_WISHES_PERSONALIZATION, FINAL_WISHES_PREPARATION, OBITUARY, WILL]
  CASE_TASK_FORM_FILLER = "case_task_form_filler", // composite of dynamic mappings and corresponding sections
  CASE_TASK_DIGITAL_VAULT = "case_task_digital_vault", // composite of dynamic mappings and corresponding sections
  LEGAL = "legal", // composite of KEY_DOCUMENTS, WILL, DECEASED_END_OF_LIFE_INFO

  // funeral
  END_OF_LIFE = "end_of_life", // funeral
  FINAL_WISHES_RESTING_PLACE = "final_wishes_resting_place", // funeral
  FINAL_WISHES_FAMILY = "final_wishes_family", // funeral
  FINAL_WISHES_SERVICE = "final_wishes_service", // funeral
  FINAL_WISHES_PERSONALIZATION = "final_wishes_personalization", // funeral
  FINAL_WISHES_PREPARATION = "final_wishes_preparation", // funeral
  OBITUARY = "obituary", // funeral
  WILL = "will", // funeral
}
