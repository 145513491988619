<template></template>
<script setup lang="ts">
import {onMounted} from "vue"
import {useConfirm} from "@/library/stores/confirm"

onMounted(() => {
  const confirm = useConfirm()
  if (window.confirm(confirm.prompt?.message || "Confirm?")) {
    confirm.accept()
  } else {
    confirm.reject()
  }
})
</script>
