import type {IBlockConfig} from "@/library/domain/questionnaires/block.interface"
import type {IPrompt} from "@/library/domain/questionnaires/prompt.interface"

export const LOCATION: ILocationConfig["type"] = "location"

export interface ILocationConfig extends IBlockConfig {
  type: "location"
  scope: string
}

export interface TLocationBlockResponseValue {
  country_id: number
  country: string
  street: string
  city: string
  province_id: number
  province: string
  postal_code: string
}

export type ILocationPrompt = IPrompt<ILocationConfig, TLocationBlockResponseValue>
