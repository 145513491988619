import type {_GettersTree, StateTree} from "pinia"
import {defineStore, type Store} from "pinia"
import {assign, cloneDeep, get, isEmpty, isEqual, mergeWith, pick, set, some, uniqueId} from "lodash"
import type {BaseSchema, ValidationError} from "yup"
import type {ComputedRef, WritableComputedRef} from "vue"
import {computed, reactive, toRaw, unref, type UnwrapRef, type WritableComputedOptions} from "vue"
import {PRODUCTION} from '@/library/domain/env'

export interface IValidityObserverOptions<IObservedState> {
  schema?: BaseSchema | null
  data?: IObservedState
}

export interface IValidityObserverState<T> extends IValidityObserverOptions<T> {
  data: T
  initialData: null | T
  touchedByPath: Record<string, boolean> // todo: rename this to touchedBySignature
}

export interface IStateValidity {
  initialData: any
  raw: any
  ref: WritableComputedRef<any>

  isValid: boolean
  isInvalid: boolean
  validationStatus: ValidationError | null

  isPristine: boolean
  isDirty: boolean
  isTouched: boolean // todo: tdd + cache this
  touch: () => void
  reset: (initialData?: any) => void
}

export type IValidityObserverStoreState<TData> = StateTree & IValidityObserverState<TData>
export type IValidityObserverStoreGetters = _GettersTree<StateTree>

export interface IValidityObserverStoreActions<TData> {
  get: (path: string) => ComputedRef<IStateValidity> // get validity
  at: (path: string) => WritableComputedRef<any> // accessor at a given path
  reset: (initialData?: Partial<TData>) => void
  _createValidationStateFor: (path: string) => IStateValidity
  _createValueProxyDefFor: (path: string) => WritableComputedOptions<any>
  boundData: () => Partial<TData>
  boundInitialData: () => Partial<TData>
  isBoundPristine: () => boolean
  isBoundDirty: () => boolean
}

export type IValidityObserverStore<TData> = Store<
  string,
  StateTree & IValidityObserverState<TData>,
  _GettersTree<StateTree>,
  IValidityObserverStoreActions<TData>
>

const computedValidationStateProvidersByPath: Record<string, ComputedRef> = {}
const computedModelProvidersByPath: Record<string, WritableComputedRef<any>> = {}

export function createValidityObserverStore<TData extends object>(
  name: string,
  {schema, data}: IValidityObserverOptions<TData>,
) {
  const store = defineStore<
    string,
    IValidityObserverStoreState<TData>,
    IValidityObserverStoreGetters,
    IValidityObserverStoreActions<TData>
  >(`validity-observer/${uniqueId()}-${name}`, {
    state: (): IValidityObserverState<TData> => ({
      schema,
      initialData: {} as TData, // shimmed as {} b/c we enforce a reset below over TData,
      data: undefined as unknown as TData, // we can assume data is always populated by `reset()` before usage
      touchedByPath: {},
    }),

    // todo: type this with `IStateValidity` to comply with composition pattern
    // todo: add getter to provide only values with `computedValidationStateProviders` to give us the equivalent of Vee:
    //       `const dataset = onlyControlled ? form.controlledValues.value : form.values`
    getters: {
      /**
       * See: https://github.com/jquense/yup?tab=readme-ov-file#schemaisvalidvalue-any-options-object-promiseboolean
       */
      isValid(): boolean {
        // todo: tests for optional schema
        return this.validationStatus === null
      },

      isInvalid(): boolean {
        return !this.isValid
      },

      validationStatus(): ValidationError | null {
        try {
          this.schema?.validateSync(this.data, {abortEarly: false}) // todo: test optional schema
        } catch (e) {
          return e as ValidationError
        }

        return null
      },

      validationStatusObject(): object {
        if (import.meta.env.MODE === PRODUCTION || !this.validationStatus) {
          return {}
        }

        return JSON.parse(JSON.stringify(this.validationStatus))
      },

      isPristine(): boolean {
        return isEqual(this.initialData, this.data)
      },

      isDirty(): boolean {
        return !this.isPristine
      },

      isTouched(): boolean {
        return some(this.touchedByPath, v => v) || !this.isPristine
      },
    },

    actions: {
      // bound data helpers
      // todo: convert these to getters once we have converted from ComputedRefImpl --> ReactiveObject
      boundData(): Partial<TData> {
        return extractDataHavingCachedProvidersFrom<TData>(this.data as TData, this.$id)
      },

      boundInitialData(): Partial<TData> {
        return extractDataHavingCachedProvidersFrom<TData>(this.initialData as TData, this.$id)
      },

      isBoundPristine() {
        return isEqual(this.boundInitialData(), this.boundData())
      },

      isBoundDirty() {
        return !this.isBoundPristine()
      },

      // validation helpers
      at(path: string) {
        if (computedModelProvidersByPath[`${this.$id}/${path}`]) {
          // bail early; rely on cached provider
          return computedModelProvidersByPath[`${this.$id}/${path}`]
        }

        return (computedModelProvidersByPath[`${this.$id}/${path}`] = computed<any>(this._createValueProxyDefFor(path)))
      },

      get(path: string): ComputedRef<IStateValidity> {
        if (computedValidationStateProvidersByPath[`${this.$id}/${path}`]) {
          // bail early; rely on cached provider
          return computedValidationStateProvidersByPath[`${this.$id}/${path}`]
        }

        // todo: extract this into a more testable module-level function
        return (computedValidationStateProvidersByPath[`${this.$id}/${path}`] = computed<IStateValidity>(
          this._createValidationStateFor.bind(this, path),
        ))
      },

      _createValueProxyDefFor(path: string): WritableComputedOptions<any> {
        return {
          get: () => get(this.data, path),
          set: v => set(this.data as object, path, v),
        }
      },

      _createValidationStateFor(path: string): IStateValidity {
        const initialData = get(this.initialData, path)
        const raw = get(this.data, path)
        const ref = this.at(path)
        const isPristine = isEqual(initialData, raw)

        let validationStatus: ValidationError | null = null
        try {
          this.schema?.validateSyncAt(path, this.data, {abortEarly: false}) // todo: test optional schema
        } catch (e) {
          validationStatus = e as ValidationError
        }

        return {
          initialData,
          raw, // should be immutable, but didn't mark as readonly for wariness on performance impact
          ref,

          isValid: isEmpty(validationStatus?.errors),
          isInvalid: !isEmpty(validationStatus?.errors),
          validationStatus,

          isPristine,
          isDirty: !isPristine,

          // todo: needs to better account for nesting/hierarchy
          // todo: should be initializing bound path's touched to false
          isTouched: !!this.touchedByPath[path],
          touch: () => (this.touchedByPath[path] = true),

          reset: (...args) => {
            // todo: write test for resetting to undefined (eg. deletion)
            const [initialData] = args
            this.initialData = set(this.initialData as object, path, cloneDeep(unref(initialData))) as UnwrapRef<TData>
            ref.value = args.length ? cloneDeep(get(this.initialData, path)) : initialData
            delete this.touchedByPath[path] // todo: how was pristine getting reset in unit tests before this implementation?
          },
        }
      },

      /**
       * Does not maintain original reactive `data` reference for implementation simplicity.
       */
      reset<TData>(initialData?: Partial<TData>): void {
        const source = initialData ?? (toRaw(this.initialData) || {})

        // todo: invert this behaviour to be a defaultsWith() equivalent (eg. val when `!key in object`)
        const normalized = mergeWith(
          source, // maintain entity reference
          schema?.cast(undefined), // populate based keys and `undefined`s
          schema?.cast(cloneDeep(source) /*, {assert: false}*/), // populate defaults
          (value, srcValue, key, object, source) => srcValue,
        )
        const backup = cloneDeep(unref(normalized)) // snapshot starting point

        this.initialData = reactive(backup)
        if (!this.data) {
          this.data = normalized
        } else {
          assign(this.data, normalized)
        }

        // todo: write tests around this: always maintain entity ref
        // if (!this.data) {
        //   this.data = normalized
        // } else {
        //   mergeWith(this.data, normalized, (objValue, srcValue, key, object) => {
        //     if (srcValue === undefined) {
        //       return null
        //     }
        //   })
        // }

        this.touchedByPath = {}
      },
    },
  })()

  store.reset(data)
  return store
}

function extractDataHavingCachedProvidersFrom<TData>(dataset: TData, cachePrefix: string) {
  const cacheKeys = [
    ...Object.keys(computedValidationStateProvidersByPath),
    ...Object.keys(computedModelProvidersByPath),
  ]
  const interestingPaths = cacheKeys
    .filter(k => k.startsWith(`${cachePrefix}/`))
    .map(k => k.substring(cachePrefix.length + 1))

  return pick(dataset, ...interestingPaths) as Partial<TData>
}
