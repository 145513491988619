import {defineStore} from "pinia"
import type {ICase} from "@/library/models/case.interface"
import {useAppFieldValueStore} from "@/library/stores/app-field-value"
import {assign} from "lodash"
import {useCaseStore} from "@/library/stores/case"

export const useActiveCasePresenter = defineStore("active-case-presenter", {
  state: () => ({}),
  getters: {
    activeCase: () => useCaseStore().activeCase,
    caseLikeRenderContext(): ICase {
      // inflate a "location" entity based on the business name when location absent for rendering via usePersonalizeWithActiveCaseName()
      const location = this.activeCase?.location?.name
        ? this.activeCase?.location
        : {id: null, name: this.activeCase?.org?.name}

      return assign({}, this.activeCase, {location, name: useAppFieldValueStore().getCaseName()})
    },
  },
})
