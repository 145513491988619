import type {RouteLocationNormalized} from "vue-router"
import {useUserStore} from "@/library/stores/user"
import {useCaseStore} from "@/library/stores/case"
import {useCaseMembersStore} from "@/library/stores/case-member"
import {useAppFieldStore} from "@/library/stores/app-field"
import {initializeLocaleWith} from "@/setup/setupi18n"
import {useMetricsDataPointStore} from "@/library/stores/metrics-data-point"
import {useAppFieldValueStore} from "@/library/stores/app-field-value"
import {usePermissionStore} from "@/library/stores/permissions"
import {useMappedAppFieldKeyStore} from "@/library/stores/mapped-app-field-key"
import {useCaseTaskStore} from "@/library/stores/case-task"
import {CaseMemberApi} from "@/library/api/case-member"
import {discoverActiveCaseIdUsing} from "@/library/route-guards/ensureRouteCaseActuallyExistsDefaultingToActiveCaseGuard"
import {useAppFieldKeyStore} from "@/library/stores/app-field-key"
import {useTaskCategoryStore} from "@/library/stores/task-category"

export async function ensureCasesContextGuard(to: RouteLocationNormalized, from?: RouteLocationNormalized) {
  const user = useUserStore().user!
  const caseStore = useCaseStore()
  const caseMembersStore = useCaseMembersStore()

  caseStore.setActiveCase(discoverActiveCaseIdUsing(to))
  if (!caseStore.activeCase) {
    // bail + send to slash (which shows notification; 0 cases on this account)
    return // todo raise exception to handle this appropriately --> shouldn't be possible
  }

  await Promise.all([
    // note: all invocations of fetchMembers() use `useActiveCase().id`; maybe encapsulate this
    caseMembersStore.fetchMembers(caseStore.activeCase.id), // todo: does cachedFetch handle unique urls properly?
    useAppFieldStore().pullAppFields(),
  ])

  // init locale
  await initializeLocaleWith(user.meta?.preferred_locale)
  // init metrics
  useMetricsDataPointStore().pull()

  // todo: extract prefetches into reusable module-level beforeEnter context guards

  // initiate prefetches in bg
  Promise.all([
    usePermissionStore().canAccessDigitalVault ? useAppFieldValueStore().pullAppFieldValues() : null,
    usePermissionStore().canAccessRoadmap ? useCaseTaskStore().pullCaseTasks(caseStore.activeCase.id) : null,
    usePermissionStore().canAccessRoadmap && usePermissionStore().canAccessQuestionnaire
      ? useAppFieldKeyStore().pull() //useMappedAppFieldKeyStore().pullForCaseWith(caseStore.activeCase.id) // todo: should this instead be appFieldKeyStore rather than mapped?
      : null,
    // below prefetch is coordinated as part of MappedAppFieldKey fetch above
    // usePermissionStore().canAccessQuestionnaire ? useCaseQuestionnaireSessionProgressStore().pull(true) : null,

    usePermissionStore().canAccessRoadmap ? useTaskCategoryStore().fetchCategories() : null,

    // @note - lastSeenAt gets touched twice
    caseMembersStore.authUserMember ? CaseMemberApi.touchLastSeenAt(caseMembersStore.authUserMember) : null,
  ])
}
