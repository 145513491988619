import {type IAppFieldPathSet} from "@/library/domain/app-fields/key-sets"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"

export const FINAL_WISHES_RESTING_PLACE_PATHSET: IAppFieldPathSet = {
  id: SectionTypes.FINAL_WISHES_RESTING_PLACE,
  paths: [
    "final_wishes.funeral.funeral_home_name",
    "final_wishes.disposition.method.type",
    "final_wishes.disposition.method.notes",
    "final_wishes.disposition.location_description",
  ],
}

export const FINAL_WISHES_FAMILY_PATHSET: IAppFieldPathSet = {
  id: SectionTypes.FINAL_WISHES_FAMILY,
  paths: [
    "final_wishes.preparations.vessel.features",
    "final_wishes.disposition.grave_marker_notes",
    "final_wishes.preparations.plot.is_purchased",
    "final_wishes.preparations.plot.notes",
    "final_wishes.preparations.funeral_insurance.is_purchased",
    "final_wishes.preparations.funeral_insurance.notes",
    "final_wishes.funeral.budget",
  ],
}

export const FINAL_WISHES_SERVICE_PATHSET: IAppFieldPathSet = {
  id: SectionTypes.FINAL_WISHES_SERVICE,
  paths: [
    "final_wishes.funeral.ceremony.type",
    "final_wishes.funeral.ceremony.notes",
    "final_wishes.funeral.preferred_officiator.name",
    "final_wishes.funeral.pallbearers",
    "final_wishes.funeral.military_honors.has_honors",
    "final_wishes.funeral.military_honors.notes",
  ],
}

export const FINAL_WISHES_PERSONALIZATION_PATHSET: IAppFieldPathSet = {
  id: SectionTypes.FINAL_WISHES_PERSONALIZATION,
  paths: [
    "final_wishes.funeral.songs",
    "final_wishes.funeral.flowers",
    "final_wishes.funeral.florist.name",
    "final_wishes.funeral.food",
    "final_wishes.funeral.media.location_description",
    "final_wishes.funeral.readings",
    "final_wishes.funeral.notes",
  ],
}

export const FINAL_WISHES_PREPARATION_PATHSET: IAppFieldPathSet = {
  id: SectionTypes.FINAL_WISHES_PREPARATION,
  paths: [
    "final_wishes.preparations.body.type",
    "final_wishes.preparations.body.notes",
    "final_wishes.funeral.viewing.type",
    "final_wishes.funeral.viewing.notes",
  ],
}
