import axios from "axios"
import type {ICaseMember} from "@/library/models/case-member.interface"

export class CaseMemberApi {
  public static async updateMeta(member: ICaseMember): Promise<ICaseMember> {
    return (
      await axios.patch<{
        data: ICaseMember
      }>(`/v3/enduser/cases/${member.case_id}/members/${member.id}/meta`, member.meta)
    ).data.data
  }

  public static touchLastSeenAt(member: ICaseMember): Promise<void> {
    return axios.put(`/v3/enduser/cases/${member.case_id}/members/${member.id}/last_seen_at`)
  }
}
