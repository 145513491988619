import {
  type IRepeatableAppFieldPathSets,
  type IRepeatableAppFieldPathSetsWithConstraints,
} from "@/library/domain/app-fields/key-sets"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"

export const PROPERTIES_PATH = "property"

export const PROPERTIES_PATHSET: IRepeatableAppFieldPathSets = {
  id: SectionTypes.PROPERTY,
  repeatable_by: "property[*]",
  paths: ["property[*].type", "property[*].name", "property[*].id", "property[*].attachment", "property[*].notes"],
}

export const FILTER_PROPERTIES__COMPUTER_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_PROPERTY__computer,
  repeatable_by: "property__computer[*]",
  constrained_by: null,
  source: PROPERTIES_PATHSET,
}
export const FILTER_PROPERTIES__FIREARM_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_PROPERTY__firearm,
  repeatable_by: "property__firearm[*]",
  constrained_by: null,
  source: PROPERTIES_PATHSET,
}
export const FILTER_PROPERTIES__REAL_ESTATE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_PROPERTY__real_estate,
  repeatable_by: "property__real_estate[*]",
  constrained_by: null,
  source: PROPERTIES_PATHSET,
}
export const FILTER_PROPERTIES__VEHICLE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_PROPERTY__vehicle,
  repeatable_by: "property__vehicle[*]",
  constrained_by: null,
  source: PROPERTIES_PATHSET,
}
