export const SETTINGS_BASE_NAME = "settings"
export const SETTINGS_EDIT_ACCOUNT_NAME = "settings-edit-account"
export const SETTINGS_SECURITY_NAME = "settings-security"
export const SETTINGS_DELETE_ACCOUNT_NAME = "settings-delete-account"

export const SETTINGS_ROUTES = [
  {
    path: "",
    name: SETTINGS_BASE_NAME,
    redirect: {name: SETTINGS_EDIT_ACCOUNT_NAME},
  },
  {
    path: "edit-account",
    name: SETTINGS_EDIT_ACCOUNT_NAME,
    component: () => import("@/settings/pages/SettingsEditAccountPage.vue"),
  },
  {
    path: "security",
    name: SETTINGS_SECURITY_NAME,
    component: () => import("@/settings/pages/SettingsSecurityPage.vue"),
  },
  {
    path: "delete",
    name: SETTINGS_DELETE_ACCOUNT_NAME,
    component: () => import("@/settings/pages/SettingsDeleteAccountPage.vue"),
  },
]
