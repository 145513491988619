import axios from "axios"
import type {IAppField} from "@/library/models/app-fields/app-field.interface"

export class DataModelItemApi {
  public static async fetch(path?: string): Promise<IAppField[]> {
    return (await axios.get<{
      data: IAppField[]
    }>(`/v3/enduser/data-model/items`, {params: path ? {path} : null})).data.data
  }
}
