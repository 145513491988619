import type {App} from "vue"
import {vMaska} from "maska"
import {vTooltip} from "@/library/directives/tooltip"
import {vFocus} from "@/library/directives/focus"

export default function setupDirectives(app: App) {
  app.directive("maska", vMaska)
  app.directive("tooltip", vTooltip)
  app.directive("focus", vFocus)
}
