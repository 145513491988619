import {SELECT_ONE} from "./prompt/select-one-prompt.interface"
import type {
  ISelectOneConfig,
  ISelectOnePrompt,
  TSelectOneBlockResponseValue,
} from "./prompt/select-one-prompt.interface"
import {SELECT_MANY} from "./prompt/select-many-prompt.interface"
import type {
  ISelectManyConfig,
  ISelectManyPrompt,
  TSelectManyBlockResponseValue,
} from "./prompt/select-many-prompt.interface"
import {STRING} from "@/library/domain/questionnaires/prompt/text-prompt.interface"
import type {
  ITextConfig,
  ITextPrompt,
  TTextBlockResponseValue,
} from "@/library/domain/questionnaires/prompt/text-prompt.interface"
import {NAME} from "@/library/domain/questionnaires/prompt/name-prompt.interface"
import type {
  INameConfig,
  INamePrompt,
  TNameBlockResponseValue,
} from "@/library/domain/questionnaires/prompt/name-prompt.interface"
import {DATE} from "@/library/domain/questionnaires/prompt/date-prompt.interface"
import type {
  IDateConfig,
  IDatePrompt,
  TDateBlockResponseValue,
} from "@/library/domain/questionnaires/prompt/date-prompt.interface"
import {LOCATION} from "@/library/domain/questionnaires/prompt/location-prompt.interface"
import type {
  ILocationConfig,
  ILocationPrompt,
  TLocationBlockResponseValue,
} from "@/library/domain/questionnaires/prompt/location-prompt.interface"
import {PHONE} from "@/library/domain/questionnaires/prompt/phone-prompt.interface"
import type {
  IPhoneConfig,
  IPhonePrompt,
  TPhoneBlockResponseValue,
} from "@/library/domain/questionnaires/prompt/phone-prompt.interface"
import {SIN} from "@/library/domain/questionnaires/prompt/sin-prompt.interface"
import type {
  ISinConfig,
  ISinPrompt,
  TSinBlockResponseValue,
} from "@/library/domain/questionnaires/prompt/sin-prompt.interface"
import {DIRECT_DEPOSIT} from "@/library/domain/questionnaires/prompt/direct-deposit.interface"
import type {
  IDirectDepositConfig,
  IDirectDepositPrompt,
  TDirectDepositBlockResponseValue,
} from "@/library/domain/questionnaires/prompt/direct-deposit.interface"
import {COUNTRY} from "@/library/domain/questionnaires/prompt/country.interface"
import type {
  ICountryConfig,
  ICountryPrompt,
  TCountryBlockResponseValue,
} from "@/library/domain/questionnaires/prompt/country.interface"
import {PROVINCE} from "@/library/domain/questionnaires/prompt/province.interface"
import type {
  IProvinceConfig,
  IProvincePrompt,
  TProvinceBlockResponseValue,
} from "@/library/domain/questionnaires/prompt/province.interface"
import {EMAIL} from "@/library/domain/questionnaires/prompt/email-prompt.interface"
import type {
  IEmailConfig,
  IEmailPrompt,
  TEmailBlockResponseValue,
} from "@/library/domain/questionnaires/prompt/email-prompt.interface"

export const SUPPORTED_BLOCK_TYPES = {
  SELECT_ONE: SELECT_ONE,
  SELECT_MANY: SELECT_MANY,
  STRING: STRING,
  NAME: NAME,
  DATE: DATE,
  LOCATION: LOCATION,
  PHONE: PHONE,
  EMAIL: EMAIL,
  SIN: SIN,
  DIRECT_DEPOSIT: DIRECT_DEPOSIT,
  COUNTRY: COUNTRY,
  PROVINCE: PROVINCE,
}

export type TBlockConfigTypes =
  | ISelectManyConfig
  | ISelectOneConfig
  | ITextConfig
  | INameConfig
  | IDateConfig
  | ILocationConfig
  | IPhoneConfig
  | IEmailConfig
  | ISinConfig
  | IDirectDepositConfig
  | ICountryConfig
  | IProvinceConfig

export type TBlockResponseValues =
  | TSelectOneBlockResponseValue
  | TSelectManyBlockResponseValue
  | TTextBlockResponseValue
  | TNameBlockResponseValue
  | TDateBlockResponseValue
  | TLocationBlockResponseValue
  | TPhoneBlockResponseValue
  | TEmailBlockResponseValue
  | TSinBlockResponseValue
  | TDirectDepositBlockResponseValue
  | TCountryBlockResponseValue
  | TProvinceBlockResponseValue
  | null

export type TPromptTypes =
  | ISelectOnePrompt
  | ISelectManyPrompt
  | ITextPrompt
  | INamePrompt
  | IDatePrompt
  | ILocationPrompt
  | IPhonePrompt
  | IEmailPrompt
  | ISinPrompt
  | IDirectDepositPrompt
  | ICountryPrompt
  | IProvincePrompt
