import {useNow} from "@vueuse/core"
import {watch} from "vue"
import {useUserStore} from "@/library/stores/user"
import {AUTH_LOGIN_NAME} from "@/auth/routes"
import router from "../router"
import {AuthUserApi} from "@/library/api/auth-user"
import {throttle} from "lodash"
import i18next from "i18next"
import {createLogger} from "@/library/domain/logger"
import {hasSufficientAuthFor, shouldMaintainNextUrlFor} from "@/library/route-guards/ensureUserAuthed"

export const AUTH_TOKEN_EXPIRATION_MINUTES = "120min" // https://github.com/cmp-cc/vue-cookies?tab=readme-ov-file#set-expire-times--input-string-type
const FIVE_SECONDS_MS = 1000 * 5
const FIVE_MINS_MS = 1000 * 5 * 60

export function setupAuthExpiration() {
  const now = useNow({interval: FIVE_SECONDS_MS})

  // when auth token expired due to age: automatically exit app
  watch(
    () => now.value && useUserStore().getToken(),
    token => {
      const route = router.currentRoute.value
      if (hasSufficientAuthFor(route, token)) {
        return // bail; valid session
      }

      createLogger().warn(
        "@/enduser/setup/setupAuthExpiration",
        "Redirecting to login page due to expired auth token.",
        {extra: {user_id: useUserStore().user?.id}},
      )
      router.push({
        name: AUTH_LOGIN_NAME,
        query: {
          ...(shouldMaintainNextUrlFor(route) ? {to: route.fullPath} : {}),
          info_message: i18next.t("auth:login_section.text--session_expired"),
        },
      })
    },
  )

  // when auth token valid and we detect activity: ping the server to say 'hello'
  document.addEventListener("mousemove", throttle(_mouseMoveHandler, FIVE_MINS_MS, {leading: true}))
}

const _mouseMoveHandler = () => {
  if (!useUserStore().getToken()) {
    return
  }

  AuthUserApi.fetch() // refresh auth token
}
