import type {IPrompt} from "@/library/domain/questionnaires/prompt.interface"
import type {IFlow} from "@/library/domain/questionnaires/flow.interface"
import type {IFlowSession} from "@/library/domain/questionnaires/flow-session"
import type {IBasicQSessionWithProgress} from "@/library/stores/case-questionnaire-session-progress"
import type {
  ISelectOneConfig,
  TSelectOneBlockResponseValue,
} from "@/library/domain/questionnaires/prompt/select-one-prompt.interface"
import type {IAppField} from "@/library/models/app-fields/app-field.interface"
import type {IQuestionnaire} from "@/library/domain/questionnaires/questionnaire.interface"
import type {ICaseQuestionnaireSession} from "@/library/models/case-questionnaire-session.interface"
import {chain, differenceBy, first, last} from "lodash"
import {useCaseQuestionnaireSessionProgressStore} from "@/library/stores/case-questionnaire-session-progress"
import {
  createDraftPromptFor,
  createResponseFor,
  getSelectionValueFrom,
  _inflateNextFlowSessionIfNecessaryOnto,
} from "@/library/domain/questionnaires/flow-session"
import {createLogger} from "@/library/domain/logger"

export interface IQSessionWithProgress extends IBasicQSessionWithProgress {
  questionnaire: IQuestionnaire
  session: ICaseQuestionnaireSession
  flowUUIDToPromptsWithSkipsMap: Record<IFlow["uuid"], IPrompt[]> // operates over `flowSessions`; caching it here.

  progress: number
  wasStarted: boolean
  isComplete: boolean
  hasSkipsOnRequired: boolean
}

export function hydrateFrom(
  values: any,
  flows: IFlow[],
  hydrationSourceDatasetName: IAppField["path"],
  questionnaireId: IQuestionnaire["id"],
): IFlowSession[] {
  if (!flows) {
    throw new Error("Unable to hydrate before loaded.")
  }

  const historicalPromptValuesByAppFieldSignature =
    useCaseQuestionnaireSessionProgressStore().historicalPromptValuesByAppFieldSignature
  // hydrate index, omitting nulls
  chain(values)
    .omitBy(v => v === undefined)
    .forEach((v, k) => (historicalPromptValuesByAppFieldSignature[`${hydrationSourceDatasetName}.${k}`] = v))
    .value()

  // init state
  const flowSessions = [] as IFlowSession[]
  // replay flows
  fastForwardAllWith(flowSessions, flows, questionnaireId)

  return flowSessions
}

// todo: when profiling, the majority of time spent is in here because it's _thrashing_ over flowSession's reactivity
//       we should find a way to batch mutations over `flowSession` such that we process locally then mutate once
//       An example: extend draft reference, do not mutate flowSession, collection prompts, then set prompts at the end and set draft
export function commitSelectionTo<TPrompt extends IPrompt>(
  draft: IFlowSession["draft"],
  selection: TPrompt["response"]["value"],
  flowSession: IFlowSession,
): IPrompt {
  if (!draft) {
    throw new Error("Unable to commit selection to absent draft")
  }

  const historicalPromptValuesByAppFieldSignature =
    useCaseQuestionnaireSessionProgressStore().historicalPromptValuesByAppFieldSignature
  const prompt: IPrompt = {...draft, response: createResponseFor(selection)}
  historicalPromptValuesByAppFieldSignature[prompt.block.app_field_signature] = prompt.response.value
  flowSession.prompts.push(prompt)

  flowSession.draft = createDraftPromptFor(
    getNextAppFieldSignatureFrom(prompt),
    flowSession.flow,
    flowSession.questionnaireId,
  )

  return prompt
}

export function rewriteSelectionAt<TPrompt extends IPrompt>(
  i: number,
  selection: TPrompt["response"]["value"],
  flowSession: IFlowSession,
): IPrompt[] {
  // roll back
  const discards = flowSession.prompts.splice(i)
  discards.forEach(p => (p.response.value = null))
  // set pointer
  flowSession.draft = createDraftPromptFor(
    first(discards)?.block.app_field_signature || null,
    flowSession.flow,
    flowSession.questionnaireId,
  )
  // apply new selection onto pointer
  const rewritten = commitSelectionTo(flowSession.draft, selection, flowSession)
  // replay
  const replayed = fastForward(flowSession)

  // provide discards that we ripped out and were not replayed
  return replayed ? differenceBy(discards, replayed.concat(rewritten), "block.app_field_signature") : discards.slice(1)
}

export function fastForward(flowSession?: IFlowSession) {
  if (!flowSession?.draft) {
    return
  }

  const historicalPromptValuesByAppFieldSignature =
    useCaseQuestionnaireSessionProgressStore().historicalPromptValuesByAppFieldSignature
  const fastForwarded: IPrompt[] = []
  while (historicalPromptValuesByAppFieldSignature[flowSession.draft?.block.app_field_signature] !== undefined) {
    const value = historicalPromptValuesByAppFieldSignature[flowSession.draft.block.app_field_signature]
    fastForwarded.push(commitSelectionTo(flowSession.draft, value, flowSession))
  }

  return fastForwarded
}

export function fastForwardAllOn(session?: IQSessionWithProgress | null) {
  if (!session) {
    createLogger().warn("library/domain/questionnaires/q-engine", "Unable to fast-forward empty session", {
      extra: {session},
    })
    return
  }

  return fastForwardAllWith(session.flowSessions, session.questionnaire.flows, session.questionnaire.id)
}

export function fastForwardAllWith(
  flowSessions: IFlowSession[] | null,
  flows: IFlow[] | null,
  questionnaireId: IQuestionnaire["id"],
) {
  // replay all blocks in each flow from index until auto-next no longer steps to another flow
  let flowSession: IFlowSession
  do {
    flowSession = last(flowSessions)!
    fastForward(flowSession)
    _inflateNextFlowSessionIfNecessaryOnto(flowSessions, flows, questionnaireId)
  } while (flowSession !== last(flowSessions))
}

export function getNextAppFieldSignatureFrom(prompt?: IPrompt | null) {
  if (!prompt) {
    return null
  }

  return (
    getSelectionValueFrom(prompt as IPrompt<ISelectOneConfig, TSelectOneBlockResponseValue>)
      ?.next_block_app_field_signature ?? prompt.block.next_block_app_field_signature
  )
}
