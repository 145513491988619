import type {IBlockConfig} from "@/library/domain/questionnaires/block.interface"
import type {IPrompt} from "@/library/domain/questionnaires/prompt.interface"

export const PROVINCE: IProvinceConfig["type"] = "province"

export interface IProvinceConfig extends IBlockConfig {
  type: "province"
}

export type TProvinceBlockResponseValue = {country_id: number; province_id: number; province: string}

export type IProvincePrompt = IPrompt<IProvinceConfig, TProvinceBlockResponseValue>
