import {AxiosError} from "axios"
import {useNotificationsStore} from "@/library/stores/notifications"
import i18next from "i18next"

export default function useErrorHandler() {
  return new ErrorHandler()
}

class ErrorHandler {
  private _options: ErrorHandlerOptions = {}

  // todo: convert this to `IValidityObserverStore`
  // withForm(form: FormContext<any>): ErrorHandler {
  //   this._options.form = form
  //   return this
  // }

  onValidationError(cb: ErrorHandlerValidationErrorCallback): ErrorHandler {
    this._options.onValidationError = cb
    return this
  }

  handle(e: any) {
    if (e instanceof AxiosError) {
      handleAxiosError(e, this._options)
    } else {
      throw e
    }
  }
}

// TYPES
export type ValidationErrors = {[field: string]: string[]}
type ErrorHandlerValidationErrorCallback = (errors: ValidationErrors, message: string) => void
type ErrorHandlerOptions = {
  onValidationError?: ErrorHandlerValidationErrorCallback
}
type ResponseData = {
  message: string
  errors: ValidationErrors | undefined
} | null

// HELPERS
function handleAxiosError(e: AxiosError<ResponseData>, options?: ErrorHandlerOptions) {
  if (isValidationError(e)) {
    handleValidationError(e, options)
  } else {
    useNotificationsStore().error(getErrorMessage(e))
  }
}

function handleValidationError(e: AxiosError<ResponseData>, options?: ErrorHandlerOptions) {
  const errors = e.response!.data!.errors as ValidationErrors
  const message = i18next.t("common:error_handler_section.invalid_data")
  let didShowValidationError = false

  // todo: set errors on validity store observer
  // if (options?.form) {
  //   options.form.setErrors(errors as any)
  //   useScrollToFirstInvalidElement()
  //   didShowValidationError = true
  // }
  if (options?.onValidationError) {
    options.onValidationError(errors, message)
    didShowValidationError = true
  }
  if (!didShowValidationError) {
    useNotificationsStore().validationError(message, errors)
  }
}

function isValidationError(e: AxiosError<ResponseData>) {
  return e.response?.status === 422 && typeof e.response?.data?.errors === "object"
}

function getErrorMessage(e: AxiosError<ResponseData>) {
  const message = e.response?.data?.message || e.response?.statusText || "Error"
  if (message === "OK") {
    return i18next.t("common:error_handler_section.error")
  }
  return message
}
