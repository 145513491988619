import type {IOptionWithNextBlock} from "../option.interface"
import type {IBlockConfig} from "../block.interface"
import type {IPrompt} from "../prompt.interface"
import type {IOption} from "../option.interface"

export const SELECT_ONE: ISelectOneConfig["type"] = "select_one"

export interface ISelectOneConfig extends IBlockConfig {
  type: "select_one"
  choices: IOptionWithNextBlock[]
  source_type: string | null
  strict: boolean
}

export type TSelectOneBlockResponseValue = IOption["value"]

export type ISelectOnePrompt = IPrompt<ISelectOneConfig, TSelectOneBlockResponseValue>
