import type {IPrompt} from "@/library/domain/questionnaires/prompt.interface"
import type {IBlockConfig} from "@/library/domain/questionnaires/block.interface"

export const PHONE: IPhoneConfig["type"] = "phone"

export interface IPhoneConfig extends IBlockConfig {
  type: "phone"
}

export type TPhoneBlockResponseValue = {digits: number; ext: number}

export type IPhonePrompt = IPrompt<IPhoneConfig, TPhoneBlockResponseValue>
