import type {IOption} from "@/library/domain/questionnaires/option.interface"
import type {IBlockConfig} from "@/library/domain/questionnaires/block.interface"
import type {IPrompt} from "@/library/domain/questionnaires/prompt.interface"

export const SELECT_MANY: ISelectManyConfig["type"] = "select_many"

export interface ISelectManyConfig extends IBlockConfig {
  type: "select_many"
  choices: IOption[]
  source_type: string
  strict: boolean
}

export type TSelectManyBlockResponseValue = IOption["value"][]

export type ISelectManyPrompt = IPrompt<ISelectManyConfig, TSelectManyBlockResponseValue>
