import type {IFlow} from "@/library/domain/questionnaires/flow.interface"
import {assign, filter, find, first, last, reduce, reject, some} from "lodash"
import type {IBlockResponse} from "@/library/domain/questionnaires/block-response.interface"
import type {IDraftPrompt, IPrompt} from "@/library/domain/questionnaires/prompt.interface"
import type {
  ISelectOneConfig,
  TSelectOneBlockResponseValue,
} from "@/library/domain/questionnaires/prompt/select-one-prompt.interface"
import type {IBlock} from "@/library/domain/questionnaires/block.interface"
import {SUPPORTED_BLOCK_TYPES} from "@/library/domain/questionnaires/q-config"
import {NOT_SURE_VALUE} from "@/library/constants/app-fields"
import type {IQSessionWithProgress} from "@/library/domain/questionnaires/q-engine"
import type {IQuestionnaire} from "@/library/domain/questionnaires/questionnaire.interface"

export interface IFlowSession {
  questionnaireId: IQuestionnaire["id"]
  flow_uuid: IFlow["uuid"] // todo: rename snake- to camel-cased
  flow: IFlow
  prompts: IPrompt[] // ordered questionnaire progress
  draft?: IDraftPrompt | null // next pending question || null once completed
}

export function inflateNextFlowSessionIfNecessaryOnto(session?: IQSessionWithProgress | null) {
  if (!session) {
    return []
  }

  return _inflateNextFlowSessionIfNecessaryOnto(
    session.flowSessions,
    session.questionnaire.flows,
    session.questionnaire.id,
  )
}

export function _inflateNextFlowSessionIfNecessaryOnto(
  flowSessions: IFlowSession[] | null,
  flows: IFlow[] | null,
  questionnaireId: IQuestionnaire["id"],
) {
  if (!flowSessions || !flows) {
    return []
  }

  const lastFlowSession = last(flowSessions)
  if (lastFlowSession?.draft == null && flowSessions.length < flows.length) {
    const flowIdForWhichToActivate = lastFlowSession?.flow.next_flow_uuid || first(flows)?.uuid
    flowIdForWhichToActivate &&
      flowSessions.push(inflateFlowSessionFrom(questionnaireId, flowIdForWhichToActivate, flows))
  }

  return flowSessions || []
}

export function inflateFlowSessionFrom(
  questionnaireId: IQuestionnaire["id"],
  flowId: IFlow["uuid"],
  flows: IFlow[],
  withDraft: boolean = true,
): IFlowSession {
  const flow = find(flows, {uuid: flowId}) as IFlow
  return {
    questionnaireId,
    flow_uuid: flowId,
    flow,
    prompts: [],
    draft: withDraft ? createDraftPromptFor(flow.first_block_app_field_signature, flow, questionnaireId) : null,
  }
}

export function createDraftPromptFor(
  appFieldSignature: IBlock["app_field_signature"] | null | undefined,
  {blocks, uuid}: IFlow,
  questionnaireId: IQuestionnaire["id"],
): IDraftPrompt | null {
  const block = find<IBlock[]>(blocks, {app_field_signature: appFieldSignature}) as IBlock

  // @ts-ignore - TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ SELECT_ONE: "select_one"; SELECT_MANY: "select_many"; STRING: "string"; NAME: "name"; DATE: "date"; LOCATION: "location"; PHONE: "phone"; SIN: "sin"; DIRECT_DEPOSIT: "direct_deposit"; COUNTRY: "country"; PROVINCE: "province"; }'.
  if (block && !SUPPORTED_BLOCK_TYPES[block.config.type.toUpperCase()]) {
    throw new Error(`Block type "${block.config.type}" is unsupported`)
  }

  return block ? {block, response: null, flowUuid: uuid, questionnaireId: questionnaireId} : null
}

export function createResponseFor(value: IBlockResponse["value"]): IBlockResponse {
  return {value}
}

// todo: refactor selection/selector details into something along the lines of Branching config vs not
export function getSelectionValueFrom({
  block,
  response: {value},
}: IPrompt<ISelectOneConfig, TSelectOneBlockResponseValue>) {
  return find(block.config.choices, {value})
}

export function generateFlowUUIDToPromptsWithSkipsMap(flowSessions: IFlowSession[] | null): Record<string, IPrompt[]> {
  return reduce(
    flowSessions,
    (memo, {flow, prompts}) => assign(memo, {[flow.uuid]: filter(prompts, {response: {value: NOT_SURE_VALUE}})}),
    {},
  )
}

export function calculateCompletionAsPercentage(flowSessions: IFlowSession[] | null, flows: IFlow[] | null): number {
  if (!flows || !flowSessions) {
    return 0
  }

  const firstFlowDoesntHaveAnAnswerYet = first(flowSessions)?.prompts.length === 0
  if (firstFlowDoesntHaveAnAnswerYet) {
    return 0
  }

  const isFinalFlowButNotYetCompleted =
    flowSessions?.length === flows.length && some(flowSessions, ({draft}) => !!draft)

  if (isFinalFlowButNotYetCompleted) {
    return 95
  }

  return (flowSessions.length / flows.length) * 100
}

export function calculateFlowCompletionAsPercentageFor(flowSession: IFlowSession | null = null) {
  if (!flowSession) {
    return 0
  }

  const {
    draft,
    prompts,
    flow_uuid,
    flow: {blocks},
  } = flowSession

  if (!prompts.length) {
    // pseudo "initialized" progress of 2%
    return 2
  }

  // solely those existing in `blocks` (exclude removed blocks)
  const promptsExistingInFlow = prompts.filter(({block: {next_block_app_field_signature}}) =>
    find(blocks, {next_block_app_field_signature: next_block_app_field_signature}),
  )
  const nonSkippedAnswers = reject(promptsExistingInFlow, {response: {value: NOT_SURE_VALUE}}).length

  // while filling out questionnaire: progress is against all possible blocks
  // after first pass: progress is against prompts we have responses to
  const percentage = nonSkippedAnswers / (draft ? blocks.length : promptsExistingInFlow.length)
  return Math.min(percentage * 100, 100) // cap at 100% in the event blocks are removed from the flow
}

export function calculateFlowSkippedAsPercentageFor(
  flowSession: IFlowSession | null = null,
  progress: IQSessionWithProgress | null,
) {
  if (!flowSession) {
    return 0
  }

  const {
    draft,
    prompts,
    flow_uuid,
    flow: {blocks},
  } = flowSession

  if (!prompts.length) {
    return 0
  }

  const skippedAnswers = progress?.flowUUIDToPromptsWithSkipsMap[flow_uuid].length || 0
  return (skippedAnswers / (draft ? blocks.length : prompts.length)) * 100
}
