import axios from "axios"
import type {ICase} from '@/library/models/case.interface'
import type {ITaskContent} from '@/library/models/task-content.interface'

export class TaskContentApi {
  public static async fetchForCase(caseId: ICase['id']): Promise<ITaskContent[]> {
    return (
      await axios.get<{
        data: ITaskContent[]
      }>(`/v3/enduser/cases/${caseId}/task-contents`)
    ).data.data
  }
}
