import {createApp} from "vue"
import App from "./App.vue"
import router from "./router"
import setupPolyfills from "@/setup/setupPolyfills"
import setupAxios from "@/setup/setupAxios"
import setupDirectives from "@/setup/setupDirectives"
import setupSentry from "@/setup/setupSentry"
import setupHubspotForms from "@/setup/setupHubspotForms"
import setupIntercom from "@/setup/setupIntercom"
import setupPlugins from "@/setup/setupPlugins"
import setupGtag from "@/setup/setupGtag"
import setupGoogleMapsApi from "@/setup/setupGoogleMapsApi"
import setupClarity from "@/setup/setupClarity"
import setupi18n from "@/setup/setupi18n"
import {setupAuthExpiration} from "@/setup/setupAuthExpiration"
import {setupSegment} from "@/setup/setupSegment"
import setupRouting from "@/setup/setupRouting"

const app = createApp(App)

setupPolyfills()
setupPlugins(app)
setupRouting(app)
setupAxios(app, router)
setupGtag(app)
setupSegment(app)
setupDirectives(app)
setupi18n(app)
setupSentry(app, router)
setupAuthExpiration()
setupHubspotForms()
setupIntercom()
setupClarity(app)
setupGoogleMapsApi()

app.mount("#app")
