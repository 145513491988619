import type {App} from "vue"
import {createPinia} from "pinia"
import router from "@/router"
import VueCookies from "vue-cookies"

export default function setupPlugins(app: App) {
  app.use(createPinia())
  app.use(router)
  app.use(VueCookies, {
    expires: "7d",
    path: "/",
    domain: import.meta.env.VITE_COOKIE_DOMAIN,
    secure: true,
    sameSite: "Strict",
  })
}
