import {defineStore} from "pinia"
import {useAppFieldStore} from "@/library/stores/app-field"
import {
  normalizeFormFillerKeySignatures,
  normalizePathsToSignatures,
  useMappedAppFieldKeyStore,
} from "@/library/stores/mapped-app-field-key"
import useActiveCase from "@/library/composables/useActiveCase"
import {type TAppFieldValueKey, useAppFieldValueStore} from "@/library/stores/app-field-value"
import {
  bundleRepeatedKeysInKeySets,
  convertSignaturesToOrderedKeys,
  generateRepeatedSectionKeysFor,
  GLOBAL_ORDERED_LABEL_MAP,
  GLOBAL_ORDERED_PATHS,
  type IAppFieldKeySet,
  type IAppFieldPathSet,
  type ICompositeAppFieldKeySet,
  type IRepeatableAppFieldPathSets,
  type IRepeatableAppFieldPathSetsWithConstraints,
  isRepeatable,
  LABEL_MAPS_BY_SECTION_ID,
  PATHS_BY_SECTION_ID,
  RepeatablePathsBySection,
  SECTIONS_IDS_BY_PATH,
} from "@/library/domain/app-fields/key-sets"
import {chain, first, keyBy, mapValues} from "lodash"
import type {IAppField, IAppFieldValueConstraint} from "@/library/models/app-fields/app-field.interface"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"
import i18next from "i18next"
import {useCaseStore} from "@/library/stores/case"

export interface IState {
  fetchPromise: null | Promise<any> // todo: standardize across stores and rename to `pullPromise`
}

interface IActions {
  pull(): Promise<any>
}

export const useAppFieldKeyStore = defineStore(
  /*<string, IState, _GettersTree<IState>, object | IActions>*/ "app-field-key",
  {
    state: (): IState => ({
      fetchPromise: null,
    }),

    getters: {
      isLoaded(): boolean {
        return useAppFieldStore().isLoaded && useMappedAppFieldKeyStore().isLoaded && useAppFieldValueStore().isLoaded
      },

      /** Only those declared in a keySet are considered "known". */
      keys(): TAppFieldValueKey[] {
        // todo: flatten out `this._keySetsWithRepeatedKeys`
        throw new Error("not yet implemented")
        // return []
      },

      _keySetsWithRepeatedKeys(): IAppFieldKeySet[] {
        // //values(groupAppFieldKeysIntoKeySets(this.keys))
        return this.isLoaded
          ? chain(/*<Record<TSectionTypes, IAppField["path"][]>, IAppFieldKeySet>*/ PATHS_BY_SECTION_ID)
              .map((paths: IAppField["path"][], id /*: TSectionTypes*/) => ({
                id,
                // assumes that keySets are either repeatable or singular; but not both.
                // eg. [deceased.name, deceased.email[*], ...] does not work just yet
                // todo: normalize paths before doing either of these branches
                keys: isRepeatable(first(paths))
                  ? generateRepeatedSectionKeysFor(id as SectionTypes)
                  : // note: normalizePathsToSignatures() fills out intermingled repeatables (aka `x[*]`) which breaks the assumption above
                    convertSignaturesToOrderedKeys(normalizeFormFillerKeySignatures(normalizePathsToSignatures(paths))),
              }))
              .reject(keySet => !keySet.keys.length)
              .value()
          : []
      },

      keySets(): ICompositeAppFieldKeySet["keySets"] {
        return bundleRepeatedKeysInKeySets(this._keySetsWithRepeatedKeys)
      },

      keySetsBySectionId(): Partial<Record<SectionTypes, ICompositeAppFieldKeySet["keySets"][number]>> {
        return keyBy(this.keySets, "id")
      },

      keysBySectionId() {
        // chain().keyBy() wouldn't resolve in type-check
        return mapValues(keyBy(this._keySetsWithRepeatedKeys, "id"), "keys")
      },
    },

    actions: {
      async pull() {
        await (this.fetchPromise = Promise.all([
          useMappedAppFieldKeyStore().pullForCaseWith(useActiveCase().id),
          useAppFieldValueStore().pullAppFieldValues(),
        ]))
      },

      registerKeySet(
        {id, paths}: IAppFieldPathSet,
        constraints?: null | IAppFieldValueConstraint | IAppFieldValueConstraint[], // constraints is no-op for now
        {discoverLabelForPath, discoverHintLabelForPath} = LabelDiscoveryService,
      ) {
        const sectionId = id as SectionTypes
        PATHS_BY_SECTION_ID[sectionId] = paths.slice()
        LABEL_MAPS_BY_SECTION_ID[sectionId] = new Map(
          paths.flatMap(p => [
            [p, discoverLabelForPath(p, sectionId) || ""],
            [`${p}--hint`, discoverHintLabelForPath(p, sectionId) || ""],
          ]),
        )

        paths.forEach(p => {
          GLOBAL_ORDERED_LABEL_MAP.set(p, discoverLabelForPath(p, sectionId) || "")
          GLOBAL_ORDERED_PATHS.push(p)
          SECTIONS_IDS_BY_PATH[p] = sectionId
        })
      },

      registerRepeatableKeySet(
        {id, paths, repeatable_by}: IRepeatableAppFieldPathSets,
        constraints?: null | IAppFieldValueConstraint | IAppFieldValueConstraint[],
      ) {
        this.registerKeySet({id, paths}, constraints)
        RepeatablePathsBySection[id as keyof typeof RepeatablePathsBySection] = repeatable_by
      },

      registerConstrainedRepeatableKeySet(pathSet: IRepeatableAppFieldPathSetsWithConstraints) {
        const {id, repeatable_by, constrained_by, source} = pathSet
        const paths = source.paths.map(p => p.replace(source.repeatable_by, repeatable_by))
        this.registerKeySet({id, paths}, constrained_by, new RepeatablesLabelDiscoveryServiceWithFallback(pathSet))
        RepeatablePathsBySection[id as keyof typeof RepeatablePathsBySection] = repeatable_by
      },
    },
  },
)

export const LabelDiscoveryService = {discoverLabelForPath, discoverHintLabelForPath}

export class RepeatablesLabelDiscoveryServiceWithFallback {
  constructor(public pathSet: IRepeatableAppFieldPathSetsWithConstraints) {}

  discoverLabelForPath = (path: TAppFieldValueKey["model"]["path"], sectionId: SectionTypes) =>
    LabelDiscoveryService.discoverLabelForPath(path, sectionId) ||
    LabelDiscoveryService.discoverLabelForPath(
      path.replace(this.pathSet.repeatable_by, this.pathSet.source.repeatable_by),
      this.pathSet.source.id as SectionTypes,
    )

  discoverHintLabelForPath = (path: TAppFieldValueKey["model"]["path"], sectionId: SectionTypes) =>
    LabelDiscoveryService.discoverHintLabelForPath(path, sectionId) ||
    LabelDiscoveryService.discoverHintLabelForPath(
      path.replace(this.pathSet.repeatable_by, this.pathSet.source.repeatable_by),
      this.pathSet.source.id as SectionTypes,
    )
}

export function discoverLabelForPath(path: TAppFieldValueKey["model"]["path"], sectionId: SectionTypes) {
  const {key, keyWithCaseState} = buildLabelKeysFor(path, sectionId)!
  return safelyGetLabelFor(keyWithCaseState) || safelyGetLabelFor(key)
}

export function discoverHintLabelForPath(path: TAppFieldValueKey["model"]["path"], sectionId: SectionTypes) {
  const {key, keyWithCaseState} = buildLabelKeysFor(path, sectionId)!
  return safelyGetLabelFor(`${keyWithCaseState}--hint`) || safelyGetLabelFor(`${key}--hint`)
}

export function safelyGetLabelFor(key: string): null | string {
  return i18next.exists(key) ? i18next.t(key) : null
}

export function buildLabelKeysFor(path: TAppFieldValueKey["model"]["path"], sectionId?: null | SectionTypes) {
  if (!sectionId) {
    return null
  }

  return {
    keyWithCaseState: `app_fields:${sectionId}_${useCaseStore().activeCaseStatusWithTransitioned}.${path}`,
    key: `app_fields:${sectionId}.${path}`,
  }
}
