import {type IRepeatableAppFieldPathSets} from "@/library/domain/app-fields/key-sets"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"

export const CHILDREN_PATHSET: IRepeatableAppFieldPathSets = {
  id: SectionTypes.CHILDREN,
  repeatable_by: "children[*]",
  paths: [
    "children[*].name",
    "children[*].date_of_birth",
    "children[*].place_of_birth",
    "children[*].social_insurance_number",
  ],
}
