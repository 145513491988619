import {type IAppFieldPathSet} from "@/library/domain/app-fields/key-sets"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"

export const SPOUSE_PERSONAL_INFO_PATHSET: IAppFieldPathSet = {
  id: SectionTypes.SPOUSE_PERSONAL_INFO,
  paths: [
    "spouse.name",
    "spouse.maiden_name",
    "spouse.name_at_birth",
    "spouse.date_of_birth",
    "spouse.place_of_birth",
    "spouse.name_social_insurance",
    "spouse.social_insurance_number",
  ],
}

export const SPOUSE_CONTACT_INFO_PATHSET: IAppFieldPathSet = {
  id: SectionTypes.SPOUSE_CONTACT_INFO,
  paths: ["spouse.phone_number", "spouse.address"],
}

export const SPOUSE_MARRIAGE_INFO_PATHSET: IAppFieldPathSet = {
  id: SectionTypes.SPOUSE_MARRIAGE_INFO,
  paths: [
    "spouse.date_of_marriage",
    "spouse.marriage_location",
    "spouse.marriage_performed_by_name",
    "spouse.witness_1",
    "spouse.witness_2",
    "spouse.reason_for_no_marriage_certificate",
  ],
}

export const SPOUSE_IMPORTANT_DATES_PATHSET: IAppFieldPathSet = {
  id: SectionTypes.SPOUSE_IMPORTANT_DATES,
  paths: [
    "spouse.date_started_living_with_spouse",
    "spouse.date_last_lived_together",
    "spouse.date_of_legal_separation",
    "spouse.date_of_divorce",
  ],
}

export const SPOUSE_PARENT_INFO_PATHSET: IAppFieldPathSet = {
  id: SectionTypes.SPOUSE_PARENT_INFO,
  paths: ["spouse.mothers_name", "spouse.fathers_name"],
}
