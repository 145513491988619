import type {IBlockConfig} from "@/library/domain/questionnaires/block.interface"
import type {IPrompt} from "@/library/domain/questionnaires/prompt.interface"

export const DIRECT_DEPOSIT: IDirectDepositConfig["type"] = "direct_deposit"

export interface IDirectDepositConfig extends IBlockConfig {
  type: "direct_deposit"
}

export type TDirectDepositBlockResponseValue = {
  branch_number: number
  financial_institution_number: number
  account_number: number
  account_name: string
  financial_institution_phone_number: number
}

export type IDirectDepositPrompt = IPrompt<IDirectDepositConfig, TDirectDepositBlockResponseValue>
