import {createLogger} from "@/library/domain/logger"

export default function setupIntercom() {
  if (!import.meta.env.VITE_INTERCOM_API_KEY) {
    createLogger().info(
      "@/client/enduser/src/setup/setupIntercom.ts",
      "Skipping initialization of Intercom due to missing VITE_INTERCOM_API_KEY",
    )
    return
  }

  // prettier-ignore
  // @ts-ignore
  // eslint-disable-next-line no-var
  // eslint-disable-next-line no-extra-semi
  ;(() => {const w=window;const ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{const d=document;const i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;const l=function(){const s=d.createElement('script');s.type='text/javascript';s.async=true;s.src=`https://widget.intercom.io/widget/${import.meta.env.VITE_INTERCOM_API_KEY}`;const x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()
}
