import {getActivePinia} from "pinia"
import type {Store, Pinia} from "pinia"

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>
}

export const resetStores = () => {
  const pinia = getActivePinia() as ExtendedPinia

  if (!pinia) {
    throw new Error("There are no stores")
  }

  // This is a hack... See https://github.com/vuejs/pinia/discussions/911 for reference.
  pinia._s.forEach(store => {
    store.$reset()
  })
}
