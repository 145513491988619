import type {IBlockConfig} from "@/library/domain/questionnaires/block.interface"
import type {IPrompt} from "@/library/domain/questionnaires/prompt.interface"

export const DATE: IDateConfig["type"] = "date"

export interface IDateConfig extends IBlockConfig {
  type: "date"
}

export type TDateBlockResponseValue = string

export type IDatePrompt = IPrompt<IDateConfig, TDateBlockResponseValue>
