import {
  type IRepeatableAppFieldPathSets,
  type IRepeatableAppFieldPathSetsWithConstraints,
} from "@/library/domain/app-fields/key-sets"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"

export const ACCOUNTS_PATH = "account"

export const ACCOUNTS_PATHSET: IRepeatableAppFieldPathSets = {
  id: SectionTypes.MEMBERSHIPS,
  repeatable_by: "account[*]",
  paths: ["account[*].type", "account[*].name", "account[*].id", "account[*].email", "account[*].notes"],
}
export const ACCOUNT_FILTER_CONTAINER_NAMES = [
  "account__digital_account__paypal",
  "account__digital_account__google",
  "account__digital_account__facebook",
  "account__digital_account__instagram",
  "account__digital_account__linkedin",
  "account__digital_account__pinterest",
  "account__digital_account__twitter",
  "account__digital_account__flickr",
]

export const FILTER_ACCOUNT__DIGITAL_ACCOUNT__PAYPAL_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_ACCOUNT__digital_account__paypal,
  repeatable_by: "account__digital_account__paypal[*]",
  constrained_by: null,
  source: ACCOUNTS_PATHSET,
}
export const FILTER_ACCOUNT__DIGITAL_ACCOUNT__GOOGLE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_ACCOUNT__digital_account__google,
  repeatable_by: "account__digital_account__google[*]",
  constrained_by: null,
  source: ACCOUNTS_PATHSET,
}
export const FILTER_ACCOUNT__DIGITAL_ACCOUNT__FACEBOOK_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_ACCOUNT__digital_account__facebook,
  repeatable_by: "account__digital_account__facebook[*]",
  constrained_by: null,
  source: ACCOUNTS_PATHSET,
}
export const FILTER_ACCOUNT__DIGITAL_ACCOUNT__INSTAGRAM_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_ACCOUNT__digital_account__instagram,
  repeatable_by: "account__digital_account__instagram[*]",
  constrained_by: null,
  source: ACCOUNTS_PATHSET,
}
export const FILTER_ACCOUNT__DIGITAL_ACCOUNT__LINKEDIN_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_ACCOUNT__digital_account__linkedin,
  repeatable_by: "account__digital_account__linkedin[*]",
  constrained_by: null,
  source: ACCOUNTS_PATHSET,
}
export const FILTER_ACCOUNT__DIGITAL_ACCOUNT__PINTEREST_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_ACCOUNT__digital_account__pinterest,
  repeatable_by: "account__digital_account__pinterest[*]",
  constrained_by: null,
  source: ACCOUNTS_PATHSET,
}
export const FILTER_ACCOUNT__DIGITAL_ACCOUNT__TWITTER_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_ACCOUNT__digital_account__twitter,
  repeatable_by: "account__digital_account__twitter[*]",
  constrained_by: null,
  source: ACCOUNTS_PATHSET,
}
export const FILTER_ACCOUNT__DIGITAL_ACCOUNT__FLICKR_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_ACCOUNT__digital_account__flickr,
  repeatable_by: "account__digital_account__flickr[*]",
  constrained_by: null,
  source: ACCOUNTS_PATHSET,
}

export const FILTER_ACCOUNT__COMPUTER_LOGIN_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_ACCOUNT__computer_login,
  repeatable_by: "account__computer_login[*]",
  constrained_by: null,
  source: ACCOUNTS_PATHSET,
}

export const FILTER_ACCOUNT__CRYPTO_WALLET_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_ACCOUNT__crypto_wallet,
  repeatable_by: "account__crypto_wallet[*]",
  constrained_by: null,
  source: ACCOUNTS_PATHSET,
}
