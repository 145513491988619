import {useAppFieldKeyStore} from "@/library/stores/app-field-key"
import {
  DECEASED_CONTACT_INFO_PATHSET,
  DECEASED_END_OF_LIFE_INFO_PATHSET,
  DECEASED_IMPORTANT_DOCS_PATHSET,
  DECEASED_PARENT_INFO_PATHSET,
  DECEASED_PERSONAL_INFO_PATHSET,
} from "@/library/domain/app-fields/key-set-registry/personal-information"
import {FUNERAL_HOME_PATHSET} from "@/library/domain/app-fields/key-set-registry/funeral-home"
import {
  SPOUSE_CONTACT_INFO_PATHSET,
  SPOUSE_IMPORTANT_DATES_PATHSET,
  SPOUSE_MARRIAGE_INFO_PATHSET,
  SPOUSE_PARENT_INFO_PATHSET,
  SPOUSE_PERSONAL_INFO_PATHSET,
} from "@/library/domain/app-fields/key-set-registry/spouse"
import {CHILDREN_PATHSET} from "@/library/domain/app-fields/key-set-registry/children"
import {CONTACTS_PATHSET} from "@/library/domain/app-fields/key-set-registry/contact"
import {APPLICANT_PATHSET} from "@/library/domain/app-fields/key-set-registry/applicant"
import {PAYMENT_PATHSET} from "@/library/domain/app-fields/key-set-registry/payment"
import {ESTATE_REPS_PATHSET} from "@/library/domain/app-fields/key-set-registry/estate-reps"
import {
  FILTER_ID_DOCUMENT__accessible_parking_permit,
  FILTER_ID_DOCUMENT__drivers_license,
  FILTER_ID_DOCUMENT__firearm_license,
  FILTER_ID_DOCUMENT__health_card,
  FILTER_ID_DOCUMENT__nexus,
  FILTER_ID_DOCUMENT__passport,
  ID_DOCUMENT_PATHSET,
} from "@/library/domain/app-fields/key-set-registry/licenses"
import {
  FILTER_KEY_DOCUMENT__MARRIAGE_CONTRACT_PATHSET,
  FILTER_KEY_DOCUMENT__MILITARY_PATHSET,
  FILTER_KEY_DOCUMENT__TAX_DOCUMENTS_PATHSET,
  FILTER_KEY_DOCUMENT__TRUST_PATHSET,
  KEY_DOCUMENT_PATHSET,
} from "@/library/domain/app-fields/key-set-registry/documents"
import {
  FILTER_INSURANCE__HEALTH_MEDICAL_PATHSET,
  FILTER_INSURANCE__HOME_PROPERTY_PATHSET,
  FILTER_INSURANCE__LIFE__BMO_PATHSET,
  FILTER_INSURANCE__LIFE__COREBRIDGE_FINANCIAL_PATHSET,
  FILTER_INSURANCE__LIFE__EQUITABLE_PATHSET,
  FILTER_INSURANCE__LIFE__INDUSTRIAL_ALLIANCE_PATHSET,
  FILTER_INSURANCE__LIFE__IVARI_PATHSET,
  FILTER_INSURANCE__LIFE__JOHN_HANCOCK_PATHSET,
  FILTER_INSURANCE__LIFE__LINCOLN_FINANCIAL_PATHSET,
  FILTER_INSURANCE__LIFE__MANULIFE_PATHSET,
  FILTER_INSURANCE__LIFE__MASSMUTUAL_PATHSET,
  FILTER_INSURANCE__LIFE__MIDLAND_NATIONAL_PATHSET,
  FILTER_INSURANCE__LIFE__NATIONAL_LIFE_PATHSET,
  FILTER_INSURANCE__LIFE__NATIONWIDE_PATHSET,
  FILTER_INSURANCE__LIFE__NEW_YORK_LIFE_PATHSET,
  FILTER_INSURANCE__LIFE__NORTHWESTERN_MUTUAL_PATHSET,
  FILTER_INSURANCE__LIFE__PACIFIC_LIFE_PATHSET,
  FILTER_INSURANCE__LIFE__PENNMUTUAL_PATHSET,
  FILTER_INSURANCE__LIFE__PRUDENTIAL_PATHSET,
  FILTER_INSURANCE__LIFE_PATHSET,
  FILTER_INSURANCE__VEHICLE_PATHSET,
  INSURANCE_PATHSET,
} from "@/library/domain/app-fields/key-set-registry/insurance"
import {
  FILTER_FINANCIAL_INFORMATION__BANK_FINANCIAL_SERVICES_PROVIDER_PATHSET,
  FILTER_FINANCIAL_INFORMATION__CREDIT_CARD_PATHSET,
  FILTER_FINANCIAL_INFORMATION__INVESTMENT_PROVIDER_PATHSET,
  FILTER_FINANCIAL_INFORMATION__LEASE_PATHSET,
  FILTER_FINANCIAL_INFORMATION__LOAN_PATHSET,
  FILTER_FINANCIAL_INFORMATION__PENSION_PATHSET,
  FILTER_FINANCIAL_INFORMATION__PHILANTHROPY_CHARITY_PATHSET,
  FINANCES_PATHSET,
} from "@/library/domain/app-fields/key-set-registry/finances"
import {
  FILTER_UTILITIES__ELECTRICITY_PATHSET,
  FILTER_UTILITIES__GAS_HYDRO_PATHSET,
  FILTER_UTILITIES__INTERNET_PATHSET,
  FILTER_UTILITIES__PHONE_PATHSET,
  FILTER_UTILITIES__PROPERTY_TAXES_PATHSET,
  FILTER_UTILITIES__WATER_PATHSET,
  UTILITIES_PATHSET,
} from "@/library/domain/app-fields/key-set-registry/utilities"
import {
  FILTER_PROPERTIES__COMPUTER_PATHSET,
  FILTER_PROPERTIES__FIREARM_PATHSET,
  FILTER_PROPERTIES__REAL_ESTATE_PATHSET,
  FILTER_PROPERTIES__VEHICLE_PATHSET,
  PROPERTIES_PATHSET,
} from "@/library/domain/app-fields/key-set-registry/properties"
import {
  ACCOUNTS_PATHSET,
  FILTER_ACCOUNT__COMPUTER_LOGIN_PATHSET,
  FILTER_ACCOUNT__CRYPTO_WALLET_PATHSET,
  FILTER_ACCOUNT__DIGITAL_ACCOUNT__FACEBOOK_PATHSET,
  FILTER_ACCOUNT__DIGITAL_ACCOUNT__FLICKR_PATHSET,
  FILTER_ACCOUNT__DIGITAL_ACCOUNT__GOOGLE_PATHSET,
  FILTER_ACCOUNT__DIGITAL_ACCOUNT__INSTAGRAM_PATHSET,
  FILTER_ACCOUNT__DIGITAL_ACCOUNT__LINKEDIN_PATHSET,
  FILTER_ACCOUNT__DIGITAL_ACCOUNT__PAYPAL_PATHSET,
  FILTER_ACCOUNT__DIGITAL_ACCOUNT__PINTEREST_PATHSET,
  FILTER_ACCOUNT__DIGITAL_ACCOUNT__TWITTER_PATHSET,
} from "@/library/domain/app-fields/key-set-registry/accounts"
import {
  BUSINESS_DOCUMENTS_PATHSET,
  FILTER_BUSINESS_DOCUMENTS__ASSET_PATHSET,
  FILTER_BUSINESS_DOCUMENTS__DIGITAL_ASSET_PATHSET,
  FILTER_BUSINESS_DOCUMENTS__INSURANCE_POLICY_PATHSET,
  FILTER_BUSINESS_DOCUMENTS__LETTER_OF_INTENT_PATHSET,
  FILTER_BUSINESS_DOCUMENTS__LIABILITY_PATHSET,
  FILTER_BUSINESS_DOCUMENTS__PROPERTY_TITLE_PATHSET,
  FILTER_BUSINESS_DOCUMENTS__SUCCESSION_PLAN_PATHSET,
  FILTER_BUSINESS_DOCUMENTS__TAX_PATHSET,
} from "@/library/domain/app-fields/key-set-registry/business_documents"
import {
  FARM_DOCUMENTS_PATHSET,
  FILTER_FARM_DOCUMENTS__AGRICULTURAL_LOAN_PATHSET,
  FILTER_FARM_DOCUMENTS__CONSERVATION_PROGRAM_CONTRACT_PATHSET,
  FILTER_FARM_DOCUMENTS__ENVIRONMENTAL_COMPLIANCE_PATHSET,
  FILTER_FARM_DOCUMENTS__EQUIPMENT_TITLE_PATHSET,
  FILTER_FARM_DOCUMENTS__ESTATE_PLAN_INTEGRATION_PATHSET,
  FILTER_FARM_DOCUMENTS__FINANCIAL_STATEMENT_PATHSET,
  FILTER_FARM_DOCUMENTS__INSURANCE_POLICY_PATHSET,
  FILTER_FARM_DOCUMENTS__LAND_TITLE_PATHSET,
  FILTER_FARM_DOCUMENTS__LEASE_PATHSET,
  FILTER_FARM_DOCUMENTS__OPERATING_AGREEMENT_PATHSET,
  FILTER_FARM_DOCUMENTS__TAX_PATHSET,
  FILTER_FARM_DOCUMENTS__TRANSFER_ON_DEATH_DEED_PATHSET,
  FILTER_FARM_DOCUMENTS__TRUST_PATHSET,
  FILTER_FARM_DOCUMENTS__WATER_RIGHTS_PATHSET,
} from "@/library/domain/app-fields/key-set-registry/farm_documents"
import {END_OF_LIFE_PATHSET} from "@/library/domain/app-fields/key-set-registry/end-of-life"
import {
  FINAL_WISHES_FAMILY_PATHSET,
  FINAL_WISHES_PERSONALIZATION_PATHSET,
  FINAL_WISHES_PREPARATION_PATHSET,
  FINAL_WISHES_RESTING_PLACE_PATHSET,
  FINAL_WISHES_SERVICE_PATHSET,
} from "@/library/domain/app-fields/key-set-registry/final-wishes"
import {OBITUARY_PATHSET} from "@/library/domain/app-fields/key-set-registry/obituary"
import {WILL_PATHSET} from "@/library/domain/app-fields/key-set-registry/will"

export function setupKeySetRegistry({
  registerKeySet,
  registerRepeatableKeySet,
  registerConstrainedRepeatableKeySet,
}: ReturnType<typeof useAppFieldKeyStore>) {
  registerKeySet(DECEASED_PERSONAL_INFO_PATHSET)
  registerKeySet(DECEASED_CONTACT_INFO_PATHSET)
  registerKeySet(DECEASED_IMPORTANT_DOCS_PATHSET)
  registerKeySet(DECEASED_PARENT_INFO_PATHSET)
  registerKeySet(DECEASED_END_OF_LIFE_INFO_PATHSET)
  registerKeySet(FUNERAL_HOME_PATHSET)
  registerKeySet(SPOUSE_PERSONAL_INFO_PATHSET)
  registerKeySet(SPOUSE_CONTACT_INFO_PATHSET)
  registerKeySet(SPOUSE_MARRIAGE_INFO_PATHSET)
  registerKeySet(SPOUSE_IMPORTANT_DATES_PATHSET)
  registerKeySet(SPOUSE_PARENT_INFO_PATHSET)
  registerRepeatableKeySet(CHILDREN_PATHSET)
  registerRepeatableKeySet(CONTACTS_PATHSET)
  registerKeySet(APPLICANT_PATHSET)
  registerKeySet(PAYMENT_PATHSET)
  registerRepeatableKeySet(ESTATE_REPS_PATHSET)

  registerRepeatableKeySet(ID_DOCUMENT_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_ID_DOCUMENT__accessible_parking_permit)
  registerConstrainedRepeatableKeySet(FILTER_ID_DOCUMENT__drivers_license)
  registerConstrainedRepeatableKeySet(FILTER_ID_DOCUMENT__firearm_license)
  registerConstrainedRepeatableKeySet(FILTER_ID_DOCUMENT__health_card)
  registerConstrainedRepeatableKeySet(FILTER_ID_DOCUMENT__nexus)
  registerConstrainedRepeatableKeySet(FILTER_ID_DOCUMENT__passport)

  registerRepeatableKeySet(KEY_DOCUMENT_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_KEY_DOCUMENT__MARRIAGE_CONTRACT_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_KEY_DOCUMENT__MILITARY_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_KEY_DOCUMENT__TAX_DOCUMENTS_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_KEY_DOCUMENT__TRUST_PATHSET)

  registerRepeatableKeySet(INSURANCE_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__LIFE__NORTHWESTERN_MUTUAL_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__LIFE__MASSMUTUAL_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__LIFE__NEW_YORK_LIFE_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__LIFE__PRUDENTIAL_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__LIFE__LINCOLN_FINANCIAL_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__LIFE__JOHN_HANCOCK_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__LIFE__PACIFIC_LIFE_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__LIFE__COREBRIDGE_FINANCIAL_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__LIFE__MIDLAND_NATIONAL_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__LIFE__NATIONWIDE_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__LIFE__PENNMUTUAL_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__LIFE__EQUITABLE_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__LIFE__NATIONAL_LIFE_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__LIFE__MANULIFE_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__LIFE__INDUSTRIAL_ALLIANCE_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__LIFE__BMO_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__LIFE__IVARI_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__HEALTH_MEDICAL_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__HOME_PROPERTY_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__LIFE_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_INSURANCE__VEHICLE_PATHSET)

  registerRepeatableKeySet(FINANCES_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FINANCIAL_INFORMATION__BANK_FINANCIAL_SERVICES_PROVIDER_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FINANCIAL_INFORMATION__CREDIT_CARD_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FINANCIAL_INFORMATION__INVESTMENT_PROVIDER_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FINANCIAL_INFORMATION__LEASE_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FINANCIAL_INFORMATION__LOAN_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FINANCIAL_INFORMATION__PENSION_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FINANCIAL_INFORMATION__PHILANTHROPY_CHARITY_PATHSET)

  registerRepeatableKeySet(UTILITIES_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_UTILITIES__ELECTRICITY_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_UTILITIES__PROPERTY_TAXES_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_UTILITIES__GAS_HYDRO_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_UTILITIES__WATER_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_UTILITIES__INTERNET_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_UTILITIES__PHONE_PATHSET)

  registerRepeatableKeySet(PROPERTIES_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_PROPERTIES__COMPUTER_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_PROPERTIES__FIREARM_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_PROPERTIES__REAL_ESTATE_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_PROPERTIES__VEHICLE_PATHSET)

  registerRepeatableKeySet(ACCOUNTS_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_ACCOUNT__DIGITAL_ACCOUNT__PAYPAL_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_ACCOUNT__DIGITAL_ACCOUNT__GOOGLE_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_ACCOUNT__DIGITAL_ACCOUNT__FACEBOOK_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_ACCOUNT__DIGITAL_ACCOUNT__INSTAGRAM_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_ACCOUNT__DIGITAL_ACCOUNT__LINKEDIN_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_ACCOUNT__DIGITAL_ACCOUNT__PINTEREST_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_ACCOUNT__DIGITAL_ACCOUNT__TWITTER_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_ACCOUNT__DIGITAL_ACCOUNT__FLICKR_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_ACCOUNT__COMPUTER_LOGIN_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_ACCOUNT__CRYPTO_WALLET_PATHSET)

  registerRepeatableKeySet(BUSINESS_DOCUMENTS_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_BUSINESS_DOCUMENTS__LETTER_OF_INTENT_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_BUSINESS_DOCUMENTS__SUCCESSION_PLAN_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_BUSINESS_DOCUMENTS__ASSET_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_BUSINESS_DOCUMENTS__LIABILITY_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_BUSINESS_DOCUMENTS__DIGITAL_ASSET_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_BUSINESS_DOCUMENTS__TAX_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_BUSINESS_DOCUMENTS__PROPERTY_TITLE_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_BUSINESS_DOCUMENTS__INSURANCE_POLICY_PATHSET)

  registerRepeatableKeySet(FARM_DOCUMENTS_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FARM_DOCUMENTS__OPERATING_AGREEMENT_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FARM_DOCUMENTS__LAND_TITLE_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FARM_DOCUMENTS__EQUIPMENT_TITLE_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FARM_DOCUMENTS__INSURANCE_POLICY_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FARM_DOCUMENTS__LEASE_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FARM_DOCUMENTS__WATER_RIGHTS_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FARM_DOCUMENTS__CONSERVATION_PROGRAM_CONTRACT_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FARM_DOCUMENTS__ENVIRONMENTAL_COMPLIANCE_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FARM_DOCUMENTS__FINANCIAL_STATEMENT_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FARM_DOCUMENTS__TAX_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FARM_DOCUMENTS__AGRICULTURAL_LOAN_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FARM_DOCUMENTS__TRANSFER_ON_DEATH_DEED_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FARM_DOCUMENTS__TRUST_PATHSET)
  registerConstrainedRepeatableKeySet(FILTER_FARM_DOCUMENTS__ESTATE_PLAN_INTEGRATION_PATHSET)

  registerKeySet(END_OF_LIFE_PATHSET)
  registerKeySet(FINAL_WISHES_RESTING_PLACE_PATHSET)
  registerKeySet(FINAL_WISHES_FAMILY_PATHSET)
  registerKeySet(FINAL_WISHES_SERVICE_PATHSET)
  registerKeySet(FINAL_WISHES_PERSONALIZATION_PATHSET)
  registerKeySet(FINAL_WISHES_PREPARATION_PATHSET)
  registerKeySet(OBITUARY_PATHSET)
  registerKeySet(WILL_PATHSET)
}
