import type {RouteLocation, RouteRecordRaw} from "vue-router"
import {ensureValidHostGuard} from "@/library/route-guards/ensureValidHostGuard"
import {ensureCoreDataGuard} from "@/library/route-guards/ensureCoreDataGuard"
import {resolveNextUrlParam} from "@/library/route-guards/resolveNextUrlParam"
import * as settingsRoutes from "@/settings/routes"
import {ensureCasesDataGuard} from "@/library/route-guards/ensureCasesDataGuard"
import {ensureRouteCaseActuallyExistsDefaultingToActiveCaseGuard} from "@/library/route-guards/ensureRouteCaseActuallyExistsDefaultingToActiveCaseGuard"
import {ensureCaseIsPaidFor} from "@/library/route-guards/ensureCaseIsPaidFor"
import {ensureCasesContextGuard} from "@/library/route-guards/ensureCasesContextGuard"
import {ensureDestinedForLandingPageForCaseGuard} from "@/library/route-guards/ensureDestinedForLandingPageForCaseGuard"
import {EXECUTOR_ASSISTANT_ROUTES} from "@/executor-assistant/routes"
import {magicLinks as magicLinkRoutes} from "@/executor-assistant/domain/magic-links/magic-links"
import * as authRoutes from "@/auth/routes"

export const ROUTE_NAME_CASES_NOT_FOUND = "cases-not-found"
export const MAINTENANCE_MODE_NAME = "maintenance-mode"
export const STYLE_GUIDE_NAME = "style-guide"
export const ROUTE_NAME_AUTHENTICATED_ROOT = "authenticated-root"
export const ROUTE_NAME_NOT_AUTHENTICATED_ROOT = "not-authenticated-root"
export const ROUTE_NAME_ACTIVE_CASE = "active-case"
export const ROUTE_NAME_NOT_FOUND = "not-found"

export const UNKNOWN_ACTIVE_CASE_ROUTE_LOCATION = {name: ROUTE_NAME_ACTIVE_CASE, params: {caseId: "__id__"}}
export const UNKNOWN_ACTIVE_CASE_PATH = "/cases/__id__/executor-assistant"

export const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: ROUTE_NAME_AUTHENTICATED_ROOT,
    redirect: UNKNOWN_ACTIVE_CASE_ROUTE_LOCATION,
    beforeEnter: [ensureValidHostGuard, ensureCoreDataGuard, resolveNextUrlParam],
    component: () => import("@/layouts/AuthenticatedLayout.vue"),
    children: [
      {
        path: "settings",
        component: () => import("@/layouts/SettingsLayout.vue"),
        children: settingsRoutes.SETTINGS_ROUTES,
      },
      {
        path: "cases",
        redirect: UNKNOWN_ACTIVE_CASE_ROUTE_LOCATION,
        children: [
          {
            path: "not-found",
            name: ROUTE_NAME_CASES_NOT_FOUND,
            component: () => import("@/library/components/routing/CasesNotFound.vue"),
          },
          {
            path: ":caseId",
            redirect: to => ({name: ROUTE_NAME_ACTIVE_CASE}),
          },
          {
            path: ":caseId/executor-assistant",
            name: ROUTE_NAME_ACTIVE_CASE,
            beforeEnter: [
              ensureCasesDataGuard,
              ensureRouteCaseActuallyExistsDefaultingToActiveCaseGuard,
              ensureCaseIsPaidFor,
              ensureCasesContextGuard,
              ensureDestinedForLandingPageForCaseGuard,
            ],

            // meta: {ignoreMetrics: true}, // todo: verify whether or not we want this
            component: () => import("@/layouts/ActiveCaseLayout.vue"),
            children: EXECUTOR_ASSISTANT_ROUTES,
            alias: "/executor-assistant/cases/:caseId", // preserve old route structure
          },
        ],
      },

      ...magicLinkRoutes,
    ],
  },
  {
    path: "/",
    name: ROUTE_NAME_NOT_AUTHENTICATED_ROOT,
    component: () => import("@/layouts/NotAuthenticatedLayout.vue"),
    beforeEnter: [ensureValidHostGuard],
    children: [
      {
        path: "/auth",
        name: authRoutes.AUTH_BASE_NAME,
        children: authRoutes.AUTH_ROUTES,
        component: () => import("@/layouts/AuthLayout.vue"),
      },
      {
        path: "/invitation",
        name: authRoutes.AUTH_CHECK_INVITATION_NAME,
        component: () => import("@/auth/pages/CheckInvitationPage.vue"),
      },
      {
        path: "/i/:shortToken",
        name: authRoutes.AUTH_CHECK_SHORT_INVITATION_NAME,
        redirect: (to: RouteLocation) => {
          return {name: authRoutes.AUTH_CHECK_INVITATION_NAME, query: {token: to.params.shortToken}}
        },
      },
      {
        path: "/maintenance",
        name: MAINTENANCE_MODE_NAME,
        component: () => import("@/library/components/maintenance/MaintenanceMode.vue"),
      },
      {
        path: "styles",
        name: STYLE_GUIDE_NAME,
        component: () => import("@/library/components/themes/StyleGuide.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: ROUTE_NAME_NOT_FOUND,
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
]
