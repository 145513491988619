import type {ICase} from "@/library/models/case.interface"
import {useCaseStore} from "@/library/stores/case"

export default function useActiveCase(): ICase {
  const activeCase = useCaseStore().activeCase
  if (!activeCase) {
    throw new Error("useActiveCase: active case not found")
  }

  return activeCase
}
