<template>
  <Modal ref="modalRef" @hidden="onHidden()">
    <template #title-text v-if="confirm.prompt?.title">
      {{ confirm.prompt?.title }}
    </template>

    <div class="mb-3 is-pii">
      <p v-if="confirm.prompt?.message" v-html="confirm.prompt?.message"></p>
      <p v-else>{{ $t('common:password_confirmation.title') }}</p>
    </div>

    <form @submit.prevent="onSubmit">
      <div class="mb-3">
        <input
          v-model="form.data.password"
          name="password"
          id="password"
          type="password"
          class="form-control"
        />
      </div>
    </form>

    <template #footer>
      <button @click="onSubmit()" :disabled="!form.isValid" class="btn btn-primary">
        {{ confirm.prompt?.confirmationLabel || $t('common:password_confirmation.submit') }}
      </button>
      <button @click="onCancel()" class="btn btn-outline-dark me-3">{{ $t('form.cancel') }}</button>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from "@/library/components/modals/Modal.vue"
import {useConfirm} from "@/library/stores/confirm"
import {nextTick, onMounted, ref} from "vue"
import * as yup from "yup"
import {createForm} from '@/form-validator/stores'
import type {InferType} from 'yup'

const confirm = useConfirm()
const modalRef = ref<typeof Modal | null>(null)
const confirmed = ref<string | null>(null)
onMounted(() => {
  nextTick(() => modalRef.value?.show())
})
const onCancel = () => {
  confirmed.value = null
  modalRef.value?.hide()
}
const onConfirm = () => {
  confirmed.value = form.data.password
  modalRef.value?.hide()
}
const onHidden = () => {
  if (confirmed.value) {
    confirm.acceptPassword(confirmed.value)
  } else {
    confirm.rejectPassword()
  }
}
const schema = yup.object({
  password: yup.string().required().label('Password')
})
const form = createForm<InferType<typeof schema>>('password-confirm', {
  schema
})
const onSubmit = onConfirm
</script>
