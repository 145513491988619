import {defineStore} from "pinia"

interface ConfirmationPrompt {
  message: string | null
  title: string | null
  confirmationLabel: string | null
  type: "alert" | "modal" | null
  acceptHandler: (() => void) | null
  rejectHandler: (() => void) | null
}

interface PasswordConfirmationPrompt {
  message: string | null
  title: string | null
  confirmationLabel: string | null
  type: "modal" | null
  acceptHandler: ((password: string) => void) | null
  rejectHandler: (() => void) | null
}
interface State {
  prompt: ConfirmationPrompt | null
  passwordPrompt: PasswordConfirmationPrompt | null
}

export const useConfirm = defineStore("confirm", {
  state: (): State => ({
    prompt: null,
    passwordPrompt: null,
  }),
  actions: {
    async confirm(message: string, title?: string, options: Partial<ConfirmationPrompt> = {}): Promise<boolean> {
      let resolve: (value: boolean | PromiseLike<boolean>) => void
      const promise = new Promise<boolean>(r => {
        resolve = r
      })
      this.$patch({
        prompt: {
          message,
          title,
          type: "modal",
          acceptHandler: () => {
            resolve(true)
            this.$patch({prompt: null})
          },
          rejectHandler: () => {
            resolve(false)
            this.$patch({prompt: null})
          },
          ...options,
        },
      })
      return promise
    },
    accept() {
      this.prompt?.acceptHandler && this.prompt.acceptHandler()
    },
    reject() {
      this.prompt?.rejectHandler && this.prompt.rejectHandler()
    },
    async passwordConfirm(
      message?: string,
      title?: string,
      options: Partial<PasswordConfirmationPrompt> = {},
    ): Promise<string | null> {
      let resolve: (value: string | null | PromiseLike<string | null>) => void
      const promise = new Promise<string | null>(r => {
        resolve = r
      })
      this.$patch({
        passwordPrompt: {
          message,
          title,
          acceptHandler: (password: string) => {
            resolve(password)
            this.$patch({passwordPrompt: null})
          },
          rejectHandler: () => {
            resolve(null)
            this.$patch({passwordPrompt: null})
          },
          ...options,
        },
      })
      return promise
    },
    acceptPassword(password: string) {
      this.passwordPrompt?.acceptHandler && this.passwordPrompt.acceptHandler(password)
    },
    rejectPassword() {
      this.passwordPrompt?.rejectHandler && this.passwordPrompt.rejectHandler()
    },
  },
})
