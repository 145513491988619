import {type IRepeatableAppFieldPathSets} from "@/library/domain/app-fields/key-sets"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"

export const CONTACTS_PATHSET: IRepeatableAppFieldPathSets = {
  id: SectionTypes.CONTACTS,
  repeatable_by: "contact[*]",
  paths: [
    "contact[*].name",
    "contact[*].date_of_birth",
    "contact[*].gender",
    "contact[*].maiden_name",
    "contact[*].professional_relationship",
    "contact[*].relationship",
    "contact[*].email.email_address",
    "contact[*].address.address_location",
    "contact[*].phone.phone_number",
    "contact[*].notes",
    // "contact[*].email.type",
    // "contact[*].address.type",
    // "contact[*].phone.type",
  ],
}
