<template>
  <div class="alert-container mt-2">
    <div>
      <Notification
        v-for="n in notificationsStore.notifications"
        :notification="n"
        class="text-center"
        @hidden="remove" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {useNotificationsStore} from "@/library/stores/notifications"
import type {INotification} from "@/library/models/notification.interface."
import Notification from "@/library/components/layout/Notification.vue"

const notificationsStore = useNotificationsStore()

const remove = (notification: INotification) => {
  notificationsStore.removeNotification(notification)
}
</script>
