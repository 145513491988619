import type {ValidationErrors} from "@/library/composables/useErrorHandler"

export interface INotification {
  id: string
  message: string
  type: NotificationType
  auto_close: number | boolean | null
  meta?: INotificationMeta
  html?: boolean
}

export interface INotificationMeta {
  errors?: ValidationErrors
}

export enum NotificationType {
  Success = "success",
  Error = "error",
  Info = "info",
  Warning = "warning",
  ValidationError = "validation_error",
}
