import type {IBlockConfig} from "@/library/domain/questionnaires/block.interface"
import type {IPrompt} from "@/library/domain/questionnaires/prompt.interface"

export const COUNTRY: ICountryConfig["type"] = "country"

export interface ICountryConfig extends IBlockConfig {
  type: "country"
}

export type TCountryBlockResponseValue = {country_id: number; country: string}

export type ICountryPrompt = IPrompt<ICountryConfig, TCountryBlockResponseValue>
