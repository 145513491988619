import {defineStore} from "pinia"
import type {IQuestionnaire} from "@/library/domain/questionnaires/questionnaire.interface"
import {QuestionnaireApi} from "@/library/api/questionnaire"
import {
  baseCollectionStoreActions,
  baseCollectionStoreGetters,
  baseCollectionStoreState,
} from "@/library/stores/_base-collection"
import {keyBy} from "lodash"
import useActiveCase from "@/library/composables/useActiveCase"
import {useCaseStore} from "@/library/stores/case"

export const useQuestionnaireStore = defineStore("questionnaire", {
  state: () => ({
    ...baseCollectionStoreState<IQuestionnaire>(),
  }),
  getters: {
    ...baseCollectionStoreGetters<IQuestionnaire>(),

    byUuid: (state): Record<IQuestionnaire["uuid"], IQuestionnaire> => keyBy(state.items, "uuid"),

    taskPlannerUuid: (state): IQuestionnaire["uuid"] => {
      const {isActiveCaseAfterCare, activeCase} = useCaseStore()
      if (!activeCase?.org?.meta) {
        return null!
      }

      const {pre_plan_questionnaire_uuid, questionnaire_uuid} = activeCase.org.meta
      return isActiveCaseAfterCare ? questionnaire_uuid : pre_plan_questionnaire_uuid
    },

    taskPlanner(): IQuestionnaire {
      return this.byUuid[this.taskPlannerUuid]
    },

    preNeedUuid: (state): IQuestionnaire["uuid"] =>
      useActiveCase().org?.meta.pre_need_questionnaire_uuid as IQuestionnaire["uuid"],

    preNeed(): IQuestionnaire {
      return this.byUuid[this.preNeedUuid]
    },
  },
  actions: {
    ...baseCollectionStoreActions<IQuestionnaire>(),

    async pullById(id: IQuestionnaire["id"]) {
      return this.upsert(await QuestionnaireApi.fetchById(id))
    },
  },
})
