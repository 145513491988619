import {defineStore} from "pinia"
import {
  baseCollectionStoreActions,
  baseCollectionStoreGetters,
  baseCollectionStoreState,
} from "@/library/stores/_base-collection"
import {NotificationsApi} from "@/library/api/notifications"
import type {
  IApiNotification,
  ICaseOwnerCollaboratorsExistNotification,
  ICaseOwnerOrgCollaboratorExistsNotification,
} from "@/library/models/api-notification.interface"
import {chain, filter, map} from "lodash"

export const NOTIFICATION_ORG_COLLABORATOR_EXISTS = "enduser-case-owner-org-collaborator-exists"
export const NOTIFICATION_COLLABORATORS_EXIST = "enduser-case-owner-collaborator-exists"
export const NOTIFICATION_ORG_COLLABORATOR_REQUESTED = "enduser-case-owner-org-collaborator-requested"
export const NOTIFICATION_ROADMAP_V2_RELEASED = "enduser-case-member-product-update-roadmap-v2"

export const useApiNotificationsStore = defineStore("api-notifications", {
  state: () => ({
    ...baseCollectionStoreState<IApiNotification>(),
  }),
  getters: {
    ...baseCollectionStoreGetters<IApiNotification>(),

    unreadOrgCollaboratorExistsNotification({items}): ICaseOwnerOrgCollaboratorExistsNotification | undefined {
      return chain(items)
        .filter({type: NOTIFICATION_ORG_COLLABORATOR_EXISTS, read_at: null}) // unread
        .first() // only handle single biz collaborator for now
        .value() as ICaseOwnerOrgCollaboratorExistsNotification | undefined
    },

    unreadCollaboratorsExistNotification({items}): ICaseOwnerCollaboratorsExistNotification | undefined {
      return chain(items)
        .filter({type: NOTIFICATION_COLLABORATORS_EXIST, read_at: null}) // unread
        .first()
        .value() as ICaseOwnerCollaboratorsExistNotification | undefined
    },

    unreadCollaborationRequestNotification({items}): IApiNotification | undefined {
      return chain(items)
        .filter({type: NOTIFICATION_ORG_COLLABORATOR_REQUESTED, read_at: null}) // unread
        .first() // only handle single biz collaborator for now
        .value()
    },

    unreadRoadmapV2ReleasedNotification({items}): IApiNotification | undefined {
      return chain(items)
        .filter({type: NOTIFICATION_ROADMAP_V2_RELEASED, read_at: null}) // unread
        .first()
        .value()
    },
  },
  actions: {
    ...baseCollectionStoreActions<IApiNotification>(),

    async pull() {
      return this.cachedFetch(NotificationsApi.fetch)
    },

    async markAllAsRead(notifications: IApiNotification[]) {
      await Promise.all(map(notifications, this.markAsRead.bind(this)))
    },

    async markAsRead({id}: IApiNotification) {
      this.upsert(await NotificationsApi.markAsRead(id))
    },
  },
})
