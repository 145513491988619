import type {IPrompt} from "@/library/domain/questionnaires/prompt.interface"
import type {IBlockConfig} from "@/library/domain/questionnaires/block.interface"

export const EMAIL: IEmailConfig["type"] = "email"

export interface IEmailConfig extends IBlockConfig {
  type: "email"
}

export type TEmailBlockResponseValue = string

export type IEmailPrompt = IPrompt<IEmailConfig, TEmailBlockResponseValue>
