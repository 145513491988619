import {defineStore} from "pinia"
import type {IQuestionnaire} from "@/library/domain/questionnaires/questionnaire.interface"
import axios from "axios"
import type {ICase} from "@/library/models/case.interface"
import {
  baseCollectionStoreActions,
  baseCollectionStoreGetters,
  baseCollectionStoreState,
} from "@/library/stores/_base-collection"
import type {ICaseQuestionnaireSession} from "@/library/models/case-questionnaire-session.interface"
import {groupBy, mapValues, maxBy} from "lodash"

export const useCaseQuestionnaireSessionStore = defineStore("case-questionnaire-session", {
  state: () => ({
    ...baseCollectionStoreState<ICaseQuestionnaireSession>(),
  }),
  getters: {
    ...baseCollectionStoreGetters<ICaseQuestionnaireSession>(),
    latestSessionByQuestionnaireId: (state): Record<IQuestionnaire["id"], ICaseQuestionnaireSession> => {
      return mapValues(
        groupBy(state.items, "questionnaire_id"),
        items => maxBy(items, "id") as ICaseQuestionnaireSession,
      )
    },
  },
  actions: {
    ...baseCollectionStoreActions<ICaseQuestionnaireSession>(),

    async pull(
      caseId: ICase["id"],
      questionnaireId: IQuestionnaire["id"] | IQuestionnaire["uuid"],
    ): Promise<ICaseQuestionnaireSession | null> {
      const response = await axios.get<{data: ICaseQuestionnaireSession | null}>(
        `/v3/enduser/cases/${caseId}/questionnaires/${questionnaireId}/session`
      )
      const session = response.data.data
      this.upsert(session)
      return session
    },
    async create(
      caseId: ICase["id"],
      questionnaireId: IQuestionnaire["id"] | IQuestionnaire["uuid"],
    ): Promise<ICaseQuestionnaireSession> {
      const response = await axios.post<{data: ICaseQuestionnaireSession}>(
        `/v3/enduser/cases/${caseId}/questionnaires/${questionnaireId}/session`
      )
      const session = response.data.data
      this.upsert(session)
      return session
    },
    async complete(
      caseId: ICase["id"],
      questionnaireId: IQuestionnaire["id"] | IQuestionnaire["uuid"],
    ): Promise<ICaseQuestionnaireSession> {
      const response = await axios.post<{data: ICaseQuestionnaireSession}>(
        `/v3/enduser/cases/${caseId}/questionnaires/${questionnaireId}/session/complete`
      )
      const session = response.data.data
      this.upsert(session)
      return session
    },
  },
})
