import type {IBlockConfig} from "@/library/domain/questionnaires/block.interface"
import type {IPrompt} from "@/library/domain/questionnaires/prompt.interface"

export const STRING: ITextConfig["type"] = "string"

export interface ITextConfig extends IBlockConfig {
  type: "string"
}

export type TTextBlockResponseValue = string

export type ITextPrompt = IPrompt<ITextConfig, TTextBlockResponseValue>
