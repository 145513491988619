import type {IBlockConfig} from "@/library/domain/questionnaires/block.interface"
import type {IPrompt} from "@/library/domain/questionnaires/prompt.interface"

export const SIN: ISinConfig["type"] = "sin"

export interface ISinConfig extends IBlockConfig {
  type: "sin"
}

export type TSinBlockResponseValue = string

export type ISinPrompt = IPrompt<ISinConfig, TSinBlockResponseValue>
