import type {IPrompt} from "@/library/domain/questionnaires/prompt.interface"
import type {IBlockConfig} from "@/library/domain/questionnaires/block.interface"

export const NAME: INameConfig["type"] = "name"

export interface INameConfig extends IBlockConfig {
  type: "name"
}

export type TNameBlockResponseValue = {first: string; middle: string; last: string}

export type INamePrompt = IPrompt<INameConfig, TNameBlockResponseValue>
