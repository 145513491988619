import type {RouteLocation, RouteLocationRaw} from "vue-router"
import {useUserStore} from "@/library/stores/user"
import {AUTH_LOGIN_NAME, AUTH_ROUTE_NAMES} from "@/auth/routes"
import {includes} from "lodash"
import {UNKNOWN_ACTIVE_CASE_PATH} from "@/routes"

export function ensureUserAuthed(to: RouteLocation): RouteLocationRaw | void {
  if (hasSufficientAuthFor(to, useUserStore().getToken())) {
    return // bail; already authed or auth-ing
  }

  return {
    name: AUTH_LOGIN_NAME,
    query: shouldMaintainNextUrlFor(to) ? {to: to.fullPath} : {},
  }
}

export function hasSufficientAuthFor(route: RouteLocation, token: null | string) {
  return token || includes(AUTH_ROUTE_NAMES, route.name)
}

export function shouldMaintainNextUrlFor(route: RouteLocation) {
  return route.fullPath && !includes(["/", UNKNOWN_ACTIVE_CASE_PATH], route.fullPath)
}
