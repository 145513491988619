import type {DirectiveBinding} from "vue"

/**
 * Used to focus elements on the page when they are mounted. e.g.
 *
 * ```HTML
 * <input v-focus/>
 *
 * <!-- focus element with a 500ms delay -->
 * <input v-focus="500"/>
 * ```
 */
export const vFocus = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    // bound value can either be bool or a number.
    // use a number to delay the focus for the number of milliseconds
    const shouldFocus = binding.value === undefined ? true : binding.value
    if (!shouldFocus) {
      return
    }
    if (typeof shouldFocus === "number") {
      setTimeout(() => el.focus(), shouldFocus)
    } else {
      el.focus()
    }
  },
}
