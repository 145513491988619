import type {RouteLocationNormalized} from "vue-router"
import {getParam} from "@/library/utils/routing"
import {useCaseStore} from "@/library/stores/case"

export async function ensureCaseIsPaidFor(to: RouteLocationNormalized, from?: RouteLocationNormalized) {
  const caseIdOnRoute = getParam(to.params.caseId)
  const activeCase = useCaseStore().byId[+caseIdOnRoute]
  if (!activeCase.checkout_url) {
    return true // bail; no stripe session initialized (aka checkout url)
  }

  window.location.href = activeCase.checkout_url!
  return false
}
