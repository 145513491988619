export const AUTH_BASE_NAME = "auth"

export const AUTH_LOGIN_NAME = "auth-login"
export const AUTH_FORGOT_PASSWORD_NAME = "auth-forgot-password"
export const AUTH_INVITE_SIGNUP_NAME = "auth-invite-signup"
export const AUTH_INVITE_DECLINE_NAME = "auth-invite-decline"
export const AUTH_CHECK_INVITATION_NAME = "auth-check-invitation"
export const AUTH_CHECK_SHORT_INVITATION_NAME = "auth-check-short-invitation"
export const AUTH_RESET_PASSWORD_NAME = "auth-reset-password"
export const AUTH_TFA_NAME = "auth-tfa"
export const AUTH_BUSINESS_NAME = "auth-business"

export const AUTH_ROUTE_NAMES = [
  AUTH_LOGIN_NAME,
  AUTH_FORGOT_PASSWORD_NAME,
  AUTH_INVITE_SIGNUP_NAME,
  AUTH_INVITE_DECLINE_NAME,
  AUTH_CHECK_INVITATION_NAME,
  AUTH_CHECK_SHORT_INVITATION_NAME,
  AUTH_RESET_PASSWORD_NAME,
  AUTH_TFA_NAME,
  AUTH_BUSINESS_NAME,
]

export const AUTH_ROUTES = [
  {
    path: "login",
    name: AUTH_LOGIN_NAME,
    component: () => import("@/auth/pages/LoginPage.vue"),
  },
  {
    path: "forgot",
    name: AUTH_FORGOT_PASSWORD_NAME,
    component: () => import("@/auth/pages/ResetPasswordEmailPage.vue"),
  },
  {
    path: "invite-signup",
    name: AUTH_INVITE_SIGNUP_NAME,
    component: () => import("@/auth/pages/InviteSignupPage.vue"),
  },
  {
    path: "invite-decline",
    name: AUTH_INVITE_DECLINE_NAME,
    component: () => import("@/auth/pages/InviteDeclinePage.vue"),
  },
  {
    path: "reset",
    name: AUTH_RESET_PASSWORD_NAME,
    component: () => import("@/auth/pages/ResetPasswordPage.vue"),
  },
  {
    path: "tfa",
    name: AUTH_TFA_NAME,
    component: () => import("@/auth/pages/TFAPage.vue"),
  },
  {
    path: "business",
    name: AUTH_BUSINESS_NAME,
    component: () => import("@/auth/pages/DefaultBusinessUserPage.vue"),
  },
]
