import type {RouteLocationNormalized, RouteLocationRaw} from "vue-router"
import {useCaseMembersStore} from "@/library/stores/case-member"
import {EXECUTOR_ASSISTANT_GRIEF_CARE_NAME, EXECUTOR_ASSISTANT_HOME_NAME} from "@/executor-assistant/routes"
import useActiveCase from "@/library/composables/useActiveCase"

import {ROUTE_NAME_ACTIVE_CASE} from "@/routes"

export async function ensureDestinedForLandingPageForCaseGuard(
  to: RouteLocationNormalized,
  from?: RouteLocationNormalized,
) {
  if (to.name !== ROUTE_NAME_ACTIVE_CASE) {
    return true // bail; no need to default anywhere b/c not hitting root active case route
  }

  return useCaseMembersStore().isAuthMemberANonContributor
    ? ({name: EXECUTOR_ASSISTANT_GRIEF_CARE_NAME, params: {caseId: useActiveCase().id}} as RouteLocationRaw)
    : ({name: EXECUTOR_ASSISTANT_HOME_NAME, params: {caseId: useActiveCase().id}} as RouteLocationRaw)
}
