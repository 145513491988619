import {type IAppFieldPathSet} from "@/library/domain/app-fields/key-sets"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"

export const APPLICANT_PATHSET: IAppFieldPathSet = {
  id: SectionTypes.APPLICANT,
  paths: [
    "applicant.name",
    "applicant.address",
    "applicant.phone_alt",
    "applicant.phone",
    "applicant.place_of_birth",
    "applicant.role",
    "applicant.social_insurance_number",
  ],
}
