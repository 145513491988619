import {
  type IRepeatableAppFieldPathSets,
  type IRepeatableAppFieldPathSetsWithConstraints,
} from "@/library/domain/app-fields/key-sets"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"

export const KEY_DOCUMENTS_PATH = "key_document"

export const KEY_DOCUMENT_PATHSET: IRepeatableAppFieldPathSets = {
  id: SectionTypes.KEY_DOCUMENTS,
  repeatable_by: "key_document[*]",
  paths: [
    "key_document[*].type",
    "key_document[*].name",
    "key_document[*].id",
    "key_document[*].location_hint",
    "key_document[*].date_created",
    "key_document[*].last_updated",
    "key_document[*].attachment",
    "key_document[*].notes",
  ],
}

export const FILTER_KEY_DOCUMENT__MARRIAGE_CONTRACT_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_KEY_DOCUMENTS__marriage_contract,
  repeatable_by: "key_document__marriage_contract[*]",
  constrained_by: null,
  source: KEY_DOCUMENT_PATHSET,
}
export const FILTER_KEY_DOCUMENT__MILITARY_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_KEY_DOCUMENTS__military,
  repeatable_by: "key_document__military[*]",
  constrained_by: null,
  source: KEY_DOCUMENT_PATHSET,
}
export const FILTER_KEY_DOCUMENT__TAX_DOCUMENTS_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_KEY_DOCUMENTS__tax_documents,
  repeatable_by: "key_document__tax_documents[*]",
  constrained_by: null,
  source: KEY_DOCUMENT_PATHSET,
}
export const FILTER_KEY_DOCUMENT__TRUST_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_KEY_DOCUMENTS__trust,
  repeatable_by: "key_document__trust[*]",
  constrained_by: null,
  source: KEY_DOCUMENT_PATHSET,
}
