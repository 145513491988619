import type {IApiNotification} from "@/library/models/api-notification.interface"
import axios from "axios"
import useActiveCase from "@/library/composables/useActiveCase"

export class NotificationsApi {
  public static async fetch() {
    const url = `/v3/enduser/cases/${useActiveCase().id}/notifications`
    return (await axios.get<{data: IApiNotification[]}>(url)).data.data
  }

  public static async markAsRead(id: IApiNotification["id"]) {
    const url = `/v3/enduser/cases/${useActiveCase().id}/notifications/${id}/read`
    return (await axios.post<IApiNotification>(url)).data
  }
}
