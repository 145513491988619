import {createRouter, createWebHistory, type RouteLocationNormalized, type RouterScrollBehavior} from "vue-router"
import {ensureUserAuthed} from "@/library/route-guards/ensureUserAuthed"
import {routes} from "@/routes"

export const scrollBehavior: RouterScrollBehavior = function (to, from, savedPosition) {
  if (to.meta.scrollToTop === false) {
    return savedPosition ? {...savedPosition, behavior: "instant"} : undefined
  }

  if (to.hash) {
    const targetEl: HTMLDivElement | null = document.querySelector(to.hash)

    if (!targetEl) {
      return
    }

    const offset = targetEl.offsetTop - window.innerHeight / 2

    return {top: offset, behavior: "instant"}
  }

  return {
    ...(savedPosition ?? {top: 0}),
    behavior: "instant",
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior,
})

// middlewares
router.beforeEach(ensureUserAuthed)

export default router
