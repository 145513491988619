import {type IAppFieldPathSet} from "@/library/domain/app-fields/key-sets"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"

export const WILL_PATH = "will"

// Owner
export const WILL_PATHSET: IAppFieldPathSet = {
  id: SectionTypes.WILL,
  paths: ["will.location_hint", "will.date_created", "will.last_updated", "will.attachment", "will.notes"],
}
