import i18next, {type InitOptions} from "i18next"
import I18NextVue from "i18next-vue"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
import type {App} from "vue"
import {createLogger} from "@/library/domain/logger"
import {PRODUCTION} from "@/library/domain/env"
import axios from "axios"
import {watch} from "vue"
import {refreshLabelMaps} from "@/library/domain/app-fields/key-sets"
import {chain, includes} from "lodash"
import type {WatchStopHandle} from "vue"
import {useActiveCasePresenter} from "@/executor-assistant/stores/active-case-presenter"

export const supportedLangs = ["en", "es" /*"fr"*/] as const
export type TSupportedLang = (typeof supportedLangs)[number]

const supportedNamespaces = [
  // allowed namespaces
  "additional_tools",
  "app_fields",
  "auth",
  "collaborator",
  "common",
  "dashboard",
  "digital_vault",
  "grief",
  "layout",
  "learn",
  "questionnaires",
  "roadmap",
  "successor",
  "questionnaire_3ba5d348-68d6-4315-8e18-17bc2e5c3f1f",
  "questionnaire_8beae0e0-5697-4717-a626-94ab69b8ab98",
  "questionnaire_ad708d06-1716-466b-9af7-9eb1740dec5a",
  "questionnaire_aec92d8d-d9f3-48bc-8d72-ecd0b6b20616",
  "questionnaire_bc3e1e95-f7e2-4fbd-8d2e-f185049bc3a8",
]

export const i18nConfiguration = {
  debug: import.meta.env.MODE !== PRODUCTION,
  returnObjects: true,

  supportedLngs: supportedLangs,
  fallbackLng: false, // prevent all fallback behaviour (defaults to `dev`, which is invalid)

  defaultNS: "common", // allow ns omission for `common` keys
  ns: supportedNamespaces,

  backend: {
    queryStringParams: {
      t: new Date().toUTCString(),
    },
  },

  saveMissing: true, // enable missing key handler
  missingKeyHandler,
  parseMissingKeyHandler,

  // todo: log out missing interpolations
  //missingInterpolationHandler(text, value, options) {},
}

export default function setupi18n(app: App) {
  const i18n = i18next.use(Backend).use(LanguageDetector)
  i18n.init(i18nConfiguration as InitOptions)
  app.use(I18NextVue, {i18next})
  return app
}

export async function initializeLocaleWith(lang?: TSupportedLang) {
  if (!lang || !includes(supportedLangs, lang)) {
    lang = supportedLangs[0] // default to `en`
  }

  await i18next.changeLanguage(lang)
  refreshLabelMaps()
  observeAppState()
  configureAxiosAcceptedLanguage()
}

function configureAxiosAcceptedLanguage() {
  axios.interceptors.request.use(config => {
    // todo: test region-specific locales to ensure we get highest q/specificity
    config.headers.set("Accept-Language", i18next.resolvedLanguage)
    return config
  })
}

let caseObserverStopHandle: null | WatchStopHandle = null

function observeAppState() {
  caseObserverStopHandle =
    caseObserverStopHandle ||
    watch(
      () => useActiveCasePresenter().caseLikeRenderContext,
      (...args) => {
        i18next.options.interpolation = {
          escapeValue: false,
          defaultVariables: {
            activeCase: useActiveCasePresenter().caseLikeRenderContext,
          },
        }
        i18next.changeLanguage(i18next.resolvedLanguage) // trigger repaint of localizations
      },
    )
}

function missingKeyHandler(
  lngs: readonly string[],
  ns: string,
  key: string,
  fallbackValue: string,
  updateMissing: boolean,
  options: any,
) {
  createLogger().error(
    "@enduser/setup/setupi18n",
    `Missing translation key: ${ns}:${key} for ${JSON.stringify(lngs)}`,
    {
      extra: {
        lngs,
        ns,
        key,
        fallbackValue,
        updateMissing,
        options,
      },
    },
  )
}

function parseMissingKeyHandler(key: string, defaultValue: string) {
  // when we have a missing translation, try to save ourselves from major embarrassment by making the output human-readable.
  // we will rely on the missingKeyHandler to notify us of the missing translation so hopefully no one notices.
  // this code will turn something like "some_namespace:thing.nested_thing" into "Nested thing"
  // the code intelligently removes '_cta' since that's a codeword, and ignores any modifiers that use
  // the '--' or '__' notation.
  return chain(
    key
      .replace("_cta", "")
      .split(/(--)|(__)/)[0]
      .split(/[.:]/),
  )
    .last()
    .startCase()
    .toLower()
    .upperFirst()
    .value()
}
