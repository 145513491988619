import {defineStore} from "pinia"

export const usePendingRequestsStore = defineStore("pending-requests", {
  state: () => ({
    startedRequests: 0,
    completedRequests: 0,
  }),
  getters: {
    hasPendingRequests: state => state.startedRequests > state.completedRequests,

    progressPercentage: state => {
      if (state.startedRequests === 0) {
        return null
      }
      if (state.startedRequests === state.completedRequests) {
        return 100
      }
      return 2.0 * state.startedRequests + 10.0 * state.completedRequests
    },
  },
  actions: {
    increment() {
      this.startedRequests++
      this._resetIfNeeded()
    },
    decrement() {
      this.completedRequests++
      this._resetIfNeeded()
    },

    _resetIfNeeded() {
      setTimeout(() => {
        if (this.startedRequests === this.completedRequests) {
          this.startedRequests = 0
          this.completedRequests = 0
        }
      }, 800)
    },
  },
})
