import {AnalyticsBrowser} from "@segment/analytics-next"
import type {IAuthUser} from "@/library/models/auth-user.interface"
import {type App, watch, type WatchStopHandle} from "vue"
import {type IMetricsDataPointContext, useMetricsDataPointStore} from "@/library/stores/metrics-data-point"
import {useUserStore} from "@/library/stores/user"
import {createPageConfigFor} from "@/setup/setupGtag"
import {find} from "lodash"
import type {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  RouteRecordNormalized,
} from "vue-router"
import {createLogger} from "@/library/domain/logger"

let _STOP_CTX_OBSERVER_HANDLE: WatchStopHandle
let _STOP_AUTH_OBSERVER_HANDLE: WatchStopHandle

export async function setupSegment(app: App) {
  if (!import.meta.env.VITE_SEGMENT_KEY) {
    createLogger().info(
      "@/client/enduser/src/setup/setupSegment.ts",
      "Skipping initialization of Segment due to missing VITE_SEGMENT_KEY",
    )

    return
  }

  const {$router: router} = app.config.globalProperties
  const metricsDataPointStore = useMetricsDataPointStore()
  analytics = fetchLibrary()

  /** scenario matrix
   * - fresh page loaded
   *   - have identity
   *   - no identity
   * - ensure first page track occurs after first identity track
   * - ensure identity track reset when logged out
   * - ensure page track happens once when page loaded
   * - ensure page track happens once when route changes
   * - ensure identity tracked when metrics context changes
   */

  // ensure track is invoked at least once with all context populated
  await metricsDataPointStore.isLoaded()
  identifySegmentUser(useUserStore().user, metricsDataPointStore.context)
  trackPage(router.currentRoute.value, router.currentRoute.value.redirectedFrom)

  _STOP_CTX_OBSERVER_HANDLE =
    _STOP_CTX_OBSERVER_HANDLE ?? // skip double-observer
    watch(
      () => (metricsDataPointStore.hasPopulatedContext ? metricsDataPointStore.context : undefined),
      (point?: IMetricsDataPointContext) => point && identifySegmentUser(useUserStore().user, point),
    )

  _STOP_AUTH_OBSERVER_HANDLE =
    _STOP_AUTH_OBSERVER_HANDLE ?? // skip double-observer
    watch(
      () => useUserStore().user, // auth token is not reactive :face_palm:
      (user: null | IAuthUser) => user || identifySegmentUser(null), // logout
    )

  // @duplicated from @/enduser/setup/setupGtag
  router.beforeResolve(
    async (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, next: NavigationGuardNext) => {
      next() // next early; no needs to block router chain of responsibility

      // normalized route meta is cumulative; instead, introspect raw route definition from matched
      const routeDef = find(to.matched, {name: to.name}) as undefined | RouteRecordNormalized
      if (routeDef?.meta?.ignoreMetrics) {
        return // explicit opt-out for layouts, etc.
      }

      await metricsDataPointStore.isLoaded()
      trackPage(to, from)
    },
  )
}

export function fetchLibrary() {
  return AnalyticsBrowser.load({writeKey: import.meta.env.VITE_SEGMENT_KEY})
}

export function identifySegmentUser(user?: null | IAuthUser, context?: IMetricsDataPointContext) {
  if (!user?.id || !context?.user_id) {
    analytics?.reset() // log out; re-gen `anonymousId`
    return
  }

  analytics?.identify(
    `${user?.id || ""}`,
    {
      email: `${user?.email || ""}`,
      username: `${user?.email || ""}`,

      ...context,
    },
    {
      integrations: {
        Intercom: {user_hash: user?.intercom_hash || null},
      },
    },
  )
}

function trackPage(to: RouteLocationNormalized, from?: RouteLocationNormalized) {
  const dest = createPageConfigFor(to)
  analytics?.page({
    name: dest.screen_name,
    path: dest.page_path,
    title: dest.page_title,
    search: window.location.search,
    referrer: document.referrer,
    referrer_name: from ? createPageConfigFor(from).screen_name : null,

    ...(to.meta?.metric || {}),
  })
}

// populated above during setup; this will get replaced by a module-level `track()`
let analytics: null | AnalyticsBrowser = null

export default analytics // this ref may be uninitialized at time of import

export function track(eventName: string, properties: any) {
  analytics?.track(eventName, {...properties, ...useMetricsDataPointStore().context})
}
