import {type IAppFieldPathSet} from "@/library/domain/app-fields/key-sets"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"

export const END_OF_LIFE_PATH = "final_wishes"

export const END_OF_LIFE_PATHSET: IAppFieldPathSet = {
  id: SectionTypes.END_OF_LIFE,
  paths: [
    "final_wishes.dependant_children.has_dependant_children",
    "final_wishes.dependant_children.notes",
    "final_wishes.pets.has_pets",
    "final_wishes.pets.notes",
    "final_wishes.organ_donor.is_organ_donor",
    "final_wishes.organ_donor.notes",
  ],
}
