import {createLogger} from "@/library/domain/logger"

export default function setupGoogleMapsApi() {
  if (!import.meta.env.VITE_GOOGLE_MAPS_API) {
    createLogger().info(
      "@/client/enduser/src/setup/setupGoogleMapsApi.ts",
      "Skipping initialization of Google Maps API due to missing VITE_GOOGLE_MAPS_API",
    )

    return
  }

  /* eslint-disable prefer-const */
  /* eslint-disable no-async-promise-executor */
  /* eslint-disable no-restricted-syntax */
  /* eslint-disable no-extra-semi */
  /* prettier-ignore */
  /* @ts-ignore */
  ;(g=>{let h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});let d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
    key: import.meta.env.VITE_GOOGLE_MAPS_API,
    v: "weekly",
  })
  /* eslint-enable prefer-const */
  /* eslint-enable no-async-promise-executor */
  /* eslint-enable no-restricted-syntax */
  /* eslint-enable no-extra-semi */
}
