<template>
  <PendingRequestLoader />
  <NotificationList />
  <Confirm />

  <div :class="{'form-elements-disabled': pendingRequestsStore.hasPendingRequests}">
    <Suspense>
      <RouterView />
    </Suspense>

    <div id="modal-teleport-destination"></div>
  </div>
</template>

<script setup lang="ts">
import PendingRequestLoader from "@/library/components/layout/PendingRequestLoader.vue"
import NotificationList from "@/library/components/layout/NotificationList.vue"
import Confirm from "@/library/components/confirm/Confirm.vue"

import {usePendingRequestsStore} from "@/library/stores/pending-requests"

const pendingRequestsStore = usePendingRequestsStore()
</script>
