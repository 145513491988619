import {chain, delay, endsWith, find, first, indexOf, map, range, startsWith, values} from "lodash"
import type {LabelMap} from "@/library/models/app-fields/app-field-labels.interface"
import type {IAppField, IAppFieldValueConstraint} from "@/library/models/app-fields/app-field.interface"
import {createKeyFromSignature, isOfList, type TAppFieldValueKey} from "@/library/stores/app-field-value"
// todo: move this work having to do with labels and localization into app fields; we should be using something else
import {useCaseStore} from "@/library/stores/case"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"
import {setupKeySetRegistry} from "@/library/domain/app-fields/key-set-registry"
import {useAppFieldKeyStore} from "@/library/stores/app-field-key"

export interface IAppFieldPathSet {
  id: string | SectionTypes
  paths: IAppField["path"][]
}

export interface IRepeatableAppFieldPathSets {
  id: string | SectionTypes
  repeatable_by: string // TRepeatables - typing is busted for now
  paths: IAppField["path"][]
}

export interface IRepeatableAppFieldPathSetsWithConstraints {
  id: string | SectionTypes
  repeatable_by: string // TRepeatables - typing is busted for now
  constrained_by?: null | IAppFieldValueConstraint[] // constraints is no-op for now
  source: IRepeatableAppFieldPathSets
}

export interface IAppFieldKeySet {
  id: string | SectionTypes
  keys: TAppFieldValueKey[]
}

export interface ICompositeAppFieldKeySet {
  id: string | SectionTypes
  keySets: (IAppFieldKeySet | IRepeatableAppFieldKeySets)[]
}

// todo: rename to IRepeatableAppFieldPathSet
export interface IRepeatableAppFieldKeySets {
  id: string | SectionTypes // todo: this could replace `repeatable_by` to generify the structure a bit
  repeatable_by: TRepeatables
  keySets: IAppFieldKeySet[]
  // Estate Representative 1
  // Estate Representative 2
  // ...
}

export function isKeySet(x: IAppFieldKeySet | IRepeatableAppFieldKeySets): x is IAppFieldKeySet {
  return "keys" in x
}

export function isRepeatableKeySets(x: IAppFieldKeySet | IRepeatableAppFieldKeySets): x is IRepeatableAppFieldKeySets {
  return "keySets" in x
}

// This is linked to frontend/projects/admin/src/app/models/digital-vault-section-type.interface.ts
// where the VAULT_SECTION_KEYS align with the TSectionTypes found below. Please ensure that changes
// to the digital vault TSectionTypes below are appropriately translated to the Admin app interface

// todo: this is borked for now -- self-referencing type due to reliance on derivation rather than declaration
export const RepeatablePathsBySection: Partial<
  Record<SectionTypes, IAppField["path"]>
> /*: Record<TSectionTypes, IRepeatableAppFieldKeySets['repeatable_by']>*/ = {} as Partial<
  Record<SectionTypes, IAppField["path"]>
>
export type TRepeatableSectionTypes = keyof typeof RepeatablePathsBySection
export type TRepeatables = (typeof RepeatablePathsBySection)[TRepeatableSectionTypes]

export const LABEL_MAPS_BY_SECTION_ID: Record<SectionTypes, LabelMap> = [] as unknown as Record<SectionTypes, LabelMap>

// todo: all of this magic is fine, but we need to make the labels aspect dynamic whereas paths and ordering can remain static
export const refreshLabelMaps = () => {
  // todo: push handler into setupi18n and maintain body as `refreshLabels`
  // refresh labels because they're not reactive right now; todo: do lookups JIT rather than storing in map

  const caseState = useCaseStore().activeCaseStatusWithTransitioned
  if (!caseState) {
    delay(refreshLabelMaps, 50)
    return
  }

  setupKeySetRegistry(useAppFieldKeyStore())
}

export const PATHS_BY_SECTION_ID: Record<SectionTypes, IAppField["path"][]> = {} as Record<
  SectionTypes,
  IAppField["path"][]
>
export const SECTIONS_IDS_BY_PATH: Record<IAppField["path"], SectionTypes> = {}

export let GLOBAL_ORDERED_LABEL_MAP: LabelMap = new Map() //generateGlobalOrderedLabelMap()

export const GLOBAL_ORDERED_PATHS: IAppField["path"][] = [] //[...GLOBAL_ORDERED_LABEL_MAP.keys()]

export const MAX_ITEMS = 30

/**
 * Based on label map, create ordered sets of keys having indices populated, max-ing out at MAX_ITEMS;
 * (based on backend's AppFieldDataModelItemProvider::maxItemsForPath()).
 */
export function generateRepeatedSectionKeysFor(section: SectionTypes, max = MAX_ITEMS): TAppFieldValueKey[] {
  const pathsForSection = [...LABEL_MAPS_BY_SECTION_ID[section].keys()] // todo: this stuff needs to go through form filler normalization process
  return chain(range(0, max))
    .map((i: number) => map(pathsForSection, path => convertPathToKeySignature(path, [i])))
    .map(signaturesForIndex => convertSignaturesToOrderedKeys(signaturesForIndex))
    .flatten()
    .value()
}

export function generateRepeatedKeySignaturesFor( // todo: consume in above function
  paths: TAppFieldValueKey["model"]["path"][],
  max = MAX_ITEMS,
): TAppFieldValueKey["signature"][] {
  // prettier-ignore
  return chain(range(0, max))
    .map((i: number) =>
      map(paths, path => isRepeatable(path)
        // supported repeatables (eg. id_document[*] -> [id_document[0], id_document[1], ...])
        ? convertPathToKeySignature(path, [i])
        // unsupported repeatables force 0th slot, relying on dedupe/uniq below
        // (eg. deceased.phone[*].phone_number -> [deceased.phone[0].phone_number, deceased.phone[0].phone_number, ...])
        : (isOfList(path) ? convertPathToKeySignature(path, [0]) : path)))
    .flatten()
    .uniq()
    .sort()
    .value()
}

export function groupAppFieldKeysIntoKeySets(keys: TAppFieldValueKey[]): Record<string, IAppFieldKeySet> {
  return chain(keys)
    .reject(({model: {path}}) => !SECTIONS_IDS_BY_PATH[path])
    .groupBy(({model: {path}}) => SECTIONS_IDS_BY_PATH[path])
    .mapValues((keys, id) => ({
      id,
      keys,
    }))
    .value()
}

// todo: break sections, repeatables and container things into isolated files

export function discoverRepeatablePathFrom(path?: IAppField["path"]) {
  return find(values(RepeatablePathsBySection), (r: string /* TRepeatables - typing is busted for now */) =>
    startsWith(path, r),
  )
}

export function isRepeatable(path?: IAppField["path"]) {
  return !!discoverRepeatablePathFrom(path)
}

export function bundleRepeatedKeysInKeySets(keySets: IAppFieldKeySet[]): ICompositeAppFieldKeySet["keySets"] {
  return map(keySets, keySet => {
    const pathForFirstKey = first(keySet.keys)?.model.path
    if (!isRepeatable(pathForFirstKey)) {
      return keySet
    }

    const repeatableKey = discoverRepeatablePathFrom(pathForFirstKey)
    return {
      id: keySet.id,
      repeatable_by: repeatableKey as TRepeatables,
      keySets: chain(keySet.keys)
        .groupBy(({model: {path}, signature}) => {
          // parse indices from signature
          const indices = extractIndicesFromSignature(signature)
          // revive indices into "repeatable portion" of this key
          let i = 0
          // @ts-ignore - replaceAll()
          return repeatableKey.replaceAll("[*]", () => `[${indices[i++]}]`)
        })
        .map((keys, id) => ({id, keys}) as IAppFieldKeySet)
        .sortBy("keys.0.indices.0") // relies on having indices extracted from signature onto key
        .value(),
    } as IRepeatableAppFieldKeySets
  })
}

export function mergeListsOfKeys(listsOfKeys: TAppFieldValueKey[][]) {
  return chain(listsOfKeys)
    .flatten()
    .uniqBy("signature")
    .sortBy(({model: {path}}) => indexOf(GLOBAL_ORDERED_PATHS, path))
    .value()
}

export function mergeAppFieldKeySets(appFieldKeySets: IAppFieldKeySet[]) {
  if (!appFieldKeySets.length) {
    return null
  }

  return {
    id: first(appFieldKeySets)!.id,
    keys: mergeListsOfKeys(map(appFieldKeySets, "keys")),
  } as IAppFieldKeySet
}

export function mergeRepeatableAppFieldKeySets(repeatableKeySets: IRepeatableAppFieldKeySets[]) {
  if (!repeatableKeySets.length) {
    return null
  }

  return {
    id: first(repeatableKeySets)!.id,
    repeatable_by: first(repeatableKeySets)!.repeatable_by,
    keySets: chain(repeatableKeySets)
      .map("keySets")
      .flatten()
      .groupBy("id")
      .map(mergeAppFieldKeySets)
      .orderBy("keys.0.indices.0")
      .value(),
  } as IRepeatableAppFieldKeySets
}

/** Merge keySets of same type; eg. list of `IRepeatableAppFieldKeySets` or list of `IAppFieldKeySet` */
export function mergeDuplicateKeySets(
  keySets: ICompositeAppFieldKeySet["keySets"],
): ICompositeAppFieldKeySet["keySets"] {
  return chain(keySets)
    .groupBy("id")
    .map(groupingOfSameKeySet =>
      isRepeatableKeySets(first(groupingOfSameKeySet)!)
        ? mergeRepeatableAppFieldKeySets(groupingOfSameKeySet as IRepeatableAppFieldKeySets[])
        : mergeAppFieldKeySets(groupingOfSameKeySet as IAppFieldKeySet[]),
    )
    .compact()
    .sortBy(keySet =>
      indexOf(
        GLOBAL_ORDERED_PATHS,
        isRepeatableKeySets(keySet) ? keySet.keySets[0].keys[0].model.path : keySet.keys[0].model.path,
      ),
    )
    .value()
}

export function convertSignaturesToOrderedKeys(signatures: TAppFieldValueKey["signature"][]) {
  return chain(signatures)
    .reject(signature => endsWith(signature, "--hint"))
    .map(signature => createKeyFromSignature(signature))
    .sortBy(({model: {path}}) => indexOf(GLOBAL_ORDERED_PATHS, path))
    .value()
}

export function convertKeySignatureToPath(signature: TAppFieldValueKey["signature"]) {
  return signature.replace(/\[\d+]/g, "[*]")
}

export function extractIndicesFromSignature(signature: TAppFieldValueKey["signature"]) {
  return map([...signature.matchAll(/\[(\d+)]/g)], matches => +matches[1])
}

export function convertPathToKeySignature(path: IAppField["path"], indices: TAppFieldValueKey["indices"]) {
  if (!indices) {
    return path
  }

  const indicesStack = indices.slice()
  return path.replace(/\[\*]/g, () => `[${indicesStack.shift() || "0"}]`)
}
