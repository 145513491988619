import {type IRepeatableAppFieldPathSets} from "@/library/domain/app-fields/key-sets"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"

export const ESTATE_REPS_PATHSET: IRepeatableAppFieldPathSets = {
  id: SectionTypes.ESTATE_REPS,
  repeatable_by: "estate_reps[*]",
  paths: [
    "estate_reps[*].name",
    "estate_reps[*].email",
    "estate_reps[*].phone",
    "estate_reps[*].primary_relationship_to_deceased",
    "estate_reps[*].secondary_relationship_to_deceased",
    "estate_reps[*].address",
    "estate_reps[*].date_of_birth",
    "estate_reps[*].social_insurance_number",
    "estate_reps[*].photo_id",
    "estate_reps[*].proof_of_authority",
  ],
}
