<template>
  <ConfirmModal v-if="confirm.prompt && confirm.prompt.type === 'modal'" />
  <ConfirmAlert v-if="confirm.prompt && confirm.prompt.type === 'alert'" />
  <PasswordConfirmModal v-if="confirm.passwordPrompt" />
</template>

<script setup lang="ts">
import {useConfirm} from "@/library/stores/confirm"
import ConfirmModal from "@/library/components/confirm/ConfirmModal.vue"
import ConfirmAlert from "@/library/components/confirm/ConfirmAlert.vue"
import PasswordConfirmModal from "@/library/components/confirm/PasswordConfirmModal.vue"

const confirm = useConfirm()
</script>
