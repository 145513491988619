import type {DirectiveBinding} from "vue"
import {Tooltip} from "bootstrap"

/**
 * ```HTML
 * <abbr v-tooltip="'tooltip text here'">Something interesting</abbr>
 *
 * <abbr v-tooltip="{
 *   text: '<p>tooltip text here</p>',
 *   delay: 500,
 *   placement: 'bottom'
 *   trigger: 'click',
 *   html: true
 * }">Customized behaviour</abbr>
 * ```
 */
export const vTooltip = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    render(el, binding)
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    if (binding.value === binding.oldValue) {
      return
    }
    render(el, binding)
  },
  beforeUnmount(el: HTMLElement) {
    hideExistingTooltipFor(el)
  },
}

function hideExistingTooltipFor(el: HTMLElement) {
  const existingTooltip = Tooltip.getInstance(el)
  if (existingTooltip) {
    existingTooltip.dispose()
  }
}

function render(el: HTMLElement, {value}: DirectiveBinding) {
  hideExistingTooltipFor(el)

  if (!value) {
    return
  }

  let text
  let delay = 300
  let placement: Tooltip.PopoverPlacement = "top"
  let trigger:
    | "hover"
    | "click"
    | "focus"
    | "manual"
    | "click hover"
    | "click focus"
    | "hover focus"
    | "click hover focus"
    | undefined = "hover"
  let html = false
  if (typeof value === "string") {
    text = value
  } else {
    text = value.text
    delay = value.delay ?? delay
    placement = value.placement ?? placement
    trigger = value.trigger ?? trigger
    html = value.html ?? html
  }

  el.setAttribute("title", text)
  new Tooltip(el, {
    delay: {show: delay, hide: 0},
    placement,
    html,
    trigger,
    animation: false,
  })
}
