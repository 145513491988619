import type {IAppField, ITagConstraint, IValueOption} from "@/library/models/app-fields/app-field.interface"
import {chain} from "lodash"

export interface ITagProvision {
  source: {
    app_field_path: IAppField["path"]
    value_option: IValueOption["value"]
    provides_tags: ITagConstraint["value"]
  }
  target: {
    app_field_path: IAppField["path"]
    value_options: IValueOption["value"][]
  }
}

export type ITargetlessTagProvision = Omit<ITagProvision, "target">

export const TAG_PROVISIONS: (ITagProvision | ITargetlessTagProvision)[] = [
  {
    source: {
      app_field_path: "account[*].type",
      value_option: "digital_account",
      provides_tags: ["digital_vault"],
    },
    target: {
      app_field_path: "account[*].name",
      value_options: [
        "amazon",
        "apple",
        "ebay",
        "facebook",
        "instagram",
        "google",
        "linkedin",
        "paypal",
        "pinterest",
        "snapchat",
        "twitter",
        "whatsapp",
        "yahoo",
        "youtube",
        "flickr",
      ],
    },
  },
  ...["computer_login", "subscription", "loyalty_rewards_program", "membership", "crypto_wallet"].map(value_option => ({
    source: {
      app_field_path: "account[*].type",
      value_option,
      provides_tags: [],
    },
  })),
]

export function discoverTagsFor(
  appField: IAppField,
  value: IValueOption["value"],
  providerTagsIndex = generateIndexForTagsBySourcePathAndValueOption(),
  targetTagsIndex = generateIndexForTagsByTargetPathAndValueOption(),
) {
  const key = buildKeyFor(appField.path, value)
  const tags = (providerTagsIndex[key] || []).concat(targetTagsIndex[key] || [])

  // empty list == constrained to none
  // null == not constrained
  return key in providerTagsIndex || key in targetTagsIndex ? tags : null
}

export function generateIndexForTagsBySourcePathAndValueOption() {
  return chain(TAG_PROVISIONS)
    .keyBy(({source: {app_field_path, value_option}}) => buildKeyFor(app_field_path, value_option))
    .mapValues("source.provides_tags")
    .value()
}

export function generateIndexForTagsByTargetPathAndValueOption() {
  return chain(TAG_PROVISIONS as ITagProvision[])
    .reject(({target}) => !target)
    .map(({source: {provides_tags}, target: {app_field_path, value_options}}) =>
      value_options.map(value_option => [buildKeyFor(app_field_path, value_option), provides_tags]),
    )
    .flatten()
    .fromPairs()
    .value()
}

export function buildKeyFor(path: IAppField["path"], option: IValueOption["value"]) {
  return `${path}@${option}`
}
