<template>
  <div
    class="alert alert-dismissible shadow p-4"
    style="cursor: default"
    :class="cssClassesForType(notification.type)"
    role="alert">
    <template v-if="notification.html">
      <div class="m-1 html"
           data-test-id="notification/content"
           v-html="notification.message"></div>
    </template>
    <template v-else>
      <div class="m-1"
           :class="{'text-start': notification.type === NotificationType.ValidationError}"
           data-test-id="notification/content">
        {{ notification.message }}
      </div>

      <ul class="text-start mb-0" v-if="notification.type === NotificationType.ValidationError">
        <li v-for="err in removeDuplicateErrors(notification.meta?.errors as ValidationErrors)" :key="err">
          {{ err }}
        </li>
      </ul>
    </template>

    <button type="button" @click="onHide(notification)" class="btn-close" aria-label="Close"></button>
  </div>
</template>

<script setup lang="ts">
import type {INotification} from "@/library/models/notification.interface."
import {NotificationType} from "@/library/models/notification.interface."
import type {ValidationErrors} from "@/library/composables/useErrorHandler"

defineProps<{
  notification: INotification
}>()

const emit = defineEmits<{
  (e: "hidden", notification: INotification): void
}>()

const cssClassesForType = (type: NotificationType) => {
  switch (type) {
    case NotificationType.Success:
      return ["border-success bg-success-subtle"]
    case NotificationType.Error:
    case NotificationType.ValidationError:
      return ["border-danger bg-danger-subtle"]
    case NotificationType.Info:
      return ["border-info bg-info-subtle"]
    case NotificationType.Warning:
      return ["border-warning bg-warning-subtle"]
  }
}

function onHide(notification: INotification) {
  emit("hidden", notification)
}

function removeDuplicateErrors(errors: ValidationErrors) {
  const flattenErrors = (Object.values(errors).flat())
  return [...new Set(flattenErrors)]
}
</script>
