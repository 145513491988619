import {
  type IRepeatableAppFieldPathSets,
  type IRepeatableAppFieldPathSetsWithConstraints,
} from "@/library/domain/app-fields/key-sets"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"

export const BUSINESS_DOCUMENTS_PATH = "business_documents"

export const BUSINESS_DOCUMENTS_PATHSET: IRepeatableAppFieldPathSets = {
  id: SectionTypes.BUSINESS_DOCUMENTS,
  repeatable_by: "business_documents[*]",
  paths: [
    "business_documents[*].type",
    "business_documents[*].business_name",
    "business_documents[*].name",
    "business_documents[*].location_hint",
    "business_documents[*].date_created",
    "business_documents[*].last_updated",
    "business_documents[*].attachment",
    "business_documents[*].notes",
  ],
}

export const FILTER_BUSINESS_DOCUMENTS__LETTER_OF_INTENT_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_BUSINESS_DOCUMENTS__letter_of_intent,
  repeatable_by: "business_documents__letter_of_intent[*]",
  constrained_by: null,
  source: BUSINESS_DOCUMENTS_PATHSET,
}
export const FILTER_BUSINESS_DOCUMENTS__SUCCESSION_PLAN_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_BUSINESS_DOCUMENTS__succession_plan,
  repeatable_by: "business_documents__succession_plan[*]",
  constrained_by: null,
  source: BUSINESS_DOCUMENTS_PATHSET,
}
export const FILTER_BUSINESS_DOCUMENTS__ASSET_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_BUSINESS_DOCUMENTS__asset,
  repeatable_by: "business_documents__asset[*]",
  constrained_by: null,
  source: BUSINESS_DOCUMENTS_PATHSET,
}
export const FILTER_BUSINESS_DOCUMENTS__LIABILITY_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_BUSINESS_DOCUMENTS__liability,
  repeatable_by: "business_documents__liability[*]",
  constrained_by: null,
  source: BUSINESS_DOCUMENTS_PATHSET,
}
export const FILTER_BUSINESS_DOCUMENTS__DIGITAL_ASSET_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_BUSINESS_DOCUMENTS__digital_asset,
  repeatable_by: "business_documents__digital_asset[*]",
  constrained_by: null,
  source: BUSINESS_DOCUMENTS_PATHSET,
}
export const FILTER_BUSINESS_DOCUMENTS__TAX_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_BUSINESS_DOCUMENTS__tax,
  repeatable_by: "business_documents__tax[*]",
  constrained_by: null,
  source: BUSINESS_DOCUMENTS_PATHSET,
}
export const FILTER_BUSINESS_DOCUMENTS__PROPERTY_TITLE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_BUSINESS_DOCUMENTS__property_title,
  repeatable_by: "business_documents__property_title[*]",
  constrained_by: null,
  source: BUSINESS_DOCUMENTS_PATHSET,
}
export const FILTER_BUSINESS_DOCUMENTS__INSURANCE_POLICY_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_BUSINESS_DOCUMENTS__insurance_policy,
  repeatable_by: "business_documents__insurance_policy[*]",
  constrained_by: null,
  source: BUSINESS_DOCUMENTS_PATHSET,
}
