import {defineStore} from "pinia"
import type {ICase} from "@/library/models/case.interface"
import {initializeLocaleWith} from "@/setup/setupi18n"
import {useUserStore} from "@/library/stores/user"
import {resetStores} from "@/library/stores/destroyable"
import {useMetricsDataPointStore} from "@/library/stores/metrics-data-point"
import * as Sentry from "@sentry/browser"
import {type RouteLocationNormalized} from "vue-router"
import {createLogger} from "@/library/domain/logger"

interface IState {
  isLegacyPlannerOnboardingModalShown: boolean | null
  isExecutorAssistantOnboardingModalShown: boolean | null
  isAckCollaboNotificationShown: boolean | null
  isReviewCollaboModalShown: boolean | null
  isAckRoadmapV2ReleasedNotificationShown: boolean | null
  isRoadmapModalShown: boolean | null
}

// See: https://en.wikipedia.org/wiki/Mediator_pattern
export const useAppMediatorStore = defineStore("app-mediator-store", {
  state: (): IState => ({
    isLegacyPlannerOnboardingModalShown: null,
    isExecutorAssistantOnboardingModalShown: null,
    isAckCollaboNotificationShown: null,
    isReviewCollaboModalShown: null,
    isAckRoadmapV2ReleasedNotificationShown: null,
    isRoadmapModalShown: null,
  }),

  getters: {},
  actions: {
    // @note: oddity exists in the fact that login() sets token, then sets user on itself
    // ... time happens...
    // then fetchAuthUser() which sets user to null while we await user response
    // can we just rely on the user we already have if we have one or at least not null it out if we know it's the same user?

    /** This should be an idempotent algorithm for bootstrapping the app with core data. */
    async whenUserEntersAppFrom(route: RouteLocationNormalized) {
      // todo: fetchAuthUser() is NOT idempotent; it resets from (user -> null -> user) on each invocation
      const user = useUserStore().user || (await useUserStore().fetchAuthUser())
      if (!user) {
        createLogger().error("@/enduser/library/stores/app-mediator", "Unable to find authed user; bailing.")
        return // yikes!
      }
    },

    async whenUserLeaves() {
      await initializeLocaleWith(undefined) // reset to `en`; multilingual is currently behind an org-based feature flag
      useUserStore().removeToken()
      resetStores()

      // ordering matters here; otherwise `resetStores()` resets isLoaded to false, pausing metrics tracking
      useMetricsDataPointStore().pull()

      Sentry.setUser(null) // todo: integrate this into setup script like: `watch(ctx => ctx ? track(ctx) : Sentry.setUser(null))`
      Sentry.setTag("app.userId", null)
      Sentry.setTag("app.userHasCadenceEmailAddress", null)
      Sentry.setTag("app.userHasDetectifyEmailAddress", null)
    },

    whenUserSwitchesCaseTo(caseId: ICase["id"]) {
      window.location.href = `/cases/${caseId}`
    },
  },
})
