import type {IPermission} from "@/library/models/permission.interface"

export enum ROLE_ID {
  ADMIN = 1,
  INDIVIDUAL = 2,
  BUSINESS_ADMIN = 3,
  BUSINESS_USER = 4,
  ENTERPRISE_ADMIN = 5,
}

export interface IRole {
  id: ROLE_ID
  name: string
  slug: string
  permissions?: IPermission[]
}
