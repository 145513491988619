import {type App, watch} from "vue"
import {createLogger} from "@/library/domain/logger"
import router from "@/router"
import type {RouteLocationNormalized, RouteRecordNormalized} from "vue-router"
import {compact, defaults, delay, find, isEmpty, pick} from "lodash"
import {EXECUTOR_ASSISTANT_DIGITAL_VAULT_SECTION_NAME} from "@/executor-assistant/routes"
import {type IMetricsDataPointContext, useMetricsDataPointStore} from "@/library/stores/metrics-data-point"

export default async function setupGtag(app: App) {
  if (!import.meta.env.VITE_GA_TAG) {
    createLogger().info(
      "@/client/enduser/src/setup/setupGtag.ts",
      `Skipping initialization of GA due to absent VITE_GA_TAG`,
    )
    return
  }

  const metricsDataPointStore = useMetricsDataPointStore()
  const gtag = fetchLibrary()

  watch(
    () => metricsDataPointStore.context,
    (point: IMetricsDataPointContext) => gtag("set", point),
    {flush: "sync"},
  )

  router.beforeResolve(async (to, from, next) => {
    next() // next early; no needs to block router chain of responsibility

    // normalized route meta is cumulative; instead, introspect raw route definition from matched
    const routeDef = find(to.matched, {name: to.name}) as undefined | RouteRecordNormalized
    if (routeDef?.meta?.ignoreMetrics) {
      return // explicit opt-out for layouts, etc.
    }

    await metricsDataPointStore.isLoaded()
    if (JSON.parse(metricsDataPointStore.context.case_is_test)) {
      return
    }

    // base config invocation triggers page_track event
    gtag("config", import.meta.env.VITE_GA_TAG, createPageConfigFor(to))
  })

  // ensure track is invoked at least once with all context populated
  await metricsDataPointStore.isLoaded()
  gtag("set", metricsDataPointStore.context)
}

function fetchLibrary() {
  defaults(window, {
    dataLayer: [],
    gtag() {
      // @ts-ignore
      window.dataLayer.push(arguments)
    },
  })

  // @ts-ignore
  window.gtag("js", new Date()) // this entry is essentially "init", needs to be Arguments instance and begins shipping event queue over the wire

  const s = document.createElement("script")
  s.async = true
  s.src = `//www.googletagmanager.com/gtag/js?id=${import.meta.env.VITE_GA_TAG}`
  document.body.appendChild(s)

  // @ts-ignore
  return window.gtag
}

export function createPageConfigFor(to: RouteLocationNormalized) {
  // disambiguate screens unique by generic route param (eg. `executor-assistant-digital-vault-section {"section": "legal"}`
  const params = to.name === EXECUTOR_ASSISTANT_DIGITAL_VAULT_SECTION_NAME ? pick(to.params, "section") : null
  const screen_name = compact([to.name?.toString(), isEmpty(params) ? null : JSON.stringify(params)]).join(" ")
  return {
    // eg. executor-assistant-digital-vault-funeral-section
    page_title: screen_name,
    screen_name,
    // omit unique caseId from path to organically aggregate analytics
    page_path: to.path.replace(/^\/cases\/\d+/, "/cases/*"),
    ...(to.meta?.metric || {}),
  }
}

export function track(eventName: string, context: IGTagEvent) {
  // @ts-ignore
  window.gtag("event", eventName, context) // todo: merge context similar to segment
}

export interface IGTagEvent {
  event_category: string
  event_action?: string
  event_label?: string
  event_value?: number
}
