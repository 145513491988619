import type {RouteLocationNormalized, RouteLocationRaw} from "vue-router"
import {useUserStore} from "@/library/stores/user"
import {AUTH_BUSINESS_NAME} from "@/auth/routes"
import {getParam} from "@/library/utils/routing"
import {first, has, pick} from "lodash"
import {ROUTE_NAME_CASES_NOT_FOUND} from "@/routes"
import {useCaseStore} from "@/library/stores/case"

export function ensureRouteCaseActuallyExistsDefaultingToActiveCaseGuard(
  to: RouteLocationNormalized,
  from?: RouteLocationNormalized,
) {
  const caseId = discoverActiveCaseIdUsing(to)
  if (!caseId) {
    return useUserStore().isOrgUser // if it's a business user, show them a dedicated helpful page
      ? {name: AUTH_BUSINESS_NAME}
      : {name: ROUTE_NAME_CASES_NOT_FOUND}
  }

  const isDiscoveredCaseIdAlreadyOnRoute = +caseId === +getParam(to.params.caseId)
  if (isDiscoveredCaseIdAlreadyOnRoute) {
    return true // bail & continue navigation; all good
  }

  // retry navigation, with verified caseId, preserving name and query params
  return {
    ...pick(to, "name", "path", "query", "hash"),
    params: {...to.params, caseId},
    replace: true,
  } as RouteLocationRaw
}

export function discoverActiveCaseIdUsing(route: RouteLocationNormalized) {
  const caseStore = useCaseStore()

  // if we're already routed to a case, set the case as active if we have it
  const caseIdOnRoute = getParam(route.params.caseId)
  if (caseIdOnRoute && has(caseStore.byId, +caseIdOnRoute)) {
    return +caseIdOnRoute
  }

  // if the case store has already set a case as active in this page load (can happen when coming back from settings)
  // then route to that
  if (caseStore.activeCase) {
    return caseStore.activeCase.id
  }

  if (!caseStore.items?.length) {
    return null // bail; no cases for this user
  }

  // otherwise, find the newest case, set it as active and route to it
  return first(caseStore.items)!.id
}
